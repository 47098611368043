import React, { useState, useEffect } from "react";
import { Spin, Table } from "antd";
import { IconButton, PrimaryButton } from "../custom_components/customButton";
import { MdAddCircle } from "react-icons/md";
import { FaTags } from "react-icons/fa";
import "./banks.scss";
import { RiDeleteBin2Fill, RiEdit2Fill } from "react-icons/ri";
import moment from "moment";
import CustomDeleteModal from "../custom_components/customDeleteModal";
import { colorScheme } from "../contants/variables";
import { error, success } from "../contants/snackbars";
import { deleteCoe, fetchCoes } from "../stores/api_calls/coes";
import { createSearchParams, useNavigate } from "react-router-dom";
import Kjur from "../stores/utils/jwt";
import { AiFillBank } from "react-icons/ai";
import { deleteBank, fetchBanks } from "../stores/api_calls/banks";
import BankForm from "../forms/banks/bankForm";

const BankIndex = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);

  const [tableData, setTableData] = useState([
    {
      date_created: moment().format("MM/DD/YY"),
      bank_name: "BPI",
      rate: "5%",
      exclusive: true,
    },
    {
      date_created: moment().format("MM/DD/YY"),
      bank_name: "BDO",
      rate: "5%",
      exclusive: true,
    },
  ]);
  const [tablePagination, setTablePagination] = useState({
    current: 1,
    pageSize: 10,
    total: "",
    defaultPageSize: 10,
    showSizeChanger: true,
    showQuickJumper: true,
    showTotal: (total) => `Total ${total} items`,
    pageSizeOptions: ["10", "20", "30"],
  });
  const [showDeleteModal, setShowDeleteModal] = useState({
    visible: false,
    data: {},
  });

  useEffect(() => {
    const pagination = {
      page: 1,
      limit: 10,
    };

    fetchData(pagination);
  }, []);

  const fetchData = (data) => {
    setIsLoading(true);

    const banks = fetchBanks(data);
    banks
      .then((res) => {
        if (res.data.success) {
          setTableData(res.data.data);
          setIsLoading(false);
          setTablePagination({
            ...tablePagination,
            total: res.data.meta.pagination.total,
          });
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e);
      });
  };

  useEffect(() => {
    if (tablePagination.current || tablePagination.pageSize) {
      const pagination = {
        page: tablePagination.current,
        limit: tablePagination.pageSize,
      };
  
      fetchData(pagination);
    }
  }, [tablePagination.current, tablePagination.pageSize]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTablePagination(pagination);
  };

  const onDelete = (record) => {
    setShowDeleteModal({ ...showDeleteModal, visible: true, data: record });
  };

  const onDeleteSubmit = () => {
    const removeBank = deleteBank(showDeleteModal.data.id);
    removeBank
      .then((res) => {
        if (res.data.success) {
          success("Bank Deleted Successfully");
          setShowDeleteModal({ ...showDeleteModal, visible: false, data: {} });
          const pagination = {
            page: 1,
            limit: 10,
          };
          fetchData(pagination);
        }
      })
      .catch((e) => {
        error(
          `Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`
        );
      });
  };

  const onEdit = (record) => {
    setShowForm({ isEditing: true, visible: true, data: record });
  };

  const columns = [
    {
      title: "Date Created",
      dataIndex: "date_created",
      key: "date_created",
      render: (text, record) => (
        <span>{moment(record.date_created).format("LL")}</span>
      ),
    },
    {
      title: "Bank Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Rate in Percentage (%)",
      dataIndex: "interest",
      key: "interest",
      width: 240,
      render: (text, record) => <span>{`${record.interest}%`}</span>,
    },
    // {
    //   title: "Exclusive",
    //   dataIndex: "exclusive",
    //   key: "exclusive",
    //   width: 240,
    //   render: (text, record) => (
    //     <span>{record.exclusive ? "true" : "false"}</span>
    //   ),
    // },
    {
      title: "Emails",
      dataIndex: "emails",
      key: "emails",
      width: 240,
      render: (text, record) =>
        record?.emails?.map((email) => <span key={email}>{email}, </span>),
    },
    {
      title: "Action",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => (
        <div className="actions-icons-container">
          <IconButton
            className="icon-button edit"
            tootTipTitle="Edit Bank"
            Icon={<RiEdit2Fill />}
            onClick={() => onEdit(record)}
          />
          <IconButton
            className="icon-button delete"
            tootTipTitle="Delete Bank"
            Icon={<RiDeleteBin2Fill />}
            onClick={() => onDelete(record)}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="career-table-container">
      <div className="table-title-icon primary">
        <AiFillBank />
      </div>
      <p className="table-title-text primary">Manage Banks</p>
      <div className="search-container">
        <div className="add-button-container">
          <PrimaryButton
            Icon={<MdAddCircle />}
            color={colorScheme.primary}
            label="Add Bank"
            onClick={() => setShowForm({ ...showForm, visible: true })}
          />
        </div>
      </div>

      <div className='scrollable-table-container'>
        <Table
          columns={columns}
          rowKey={(record) => record._id}
          dataSource={tableData}
          loading={{ indicator: <Spin size="large" />, spinning: isLoading }}
          pagination={tablePagination}
          onChange={handleTableChange}
          bordered
          sticky
        />
      </div>

      <BankForm
        visible={showForm.visible}
        onCancel={() => setShowForm({ ...showForm, visible: false, isEditing: false })}
        isEditing={showForm.isEditing}
        data={showForm.data}
        fetchData={fetchData}
      />

      <CustomDeleteModal
        title={`Delete Bank`}
        visible={showDeleteModal.visible}
        onSubmit={onDeleteSubmit}
        onCancel={() => {
          setShowDeleteModal({ ...showDeleteModal, visible: false, data: {} });
        }}
        data={{ itemName: `${showDeleteModal.data.name}` }}
      />
    </div>
  );
};

export default BankIndex;
