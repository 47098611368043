import React , {useState , useEffect, useContext} from 'react'
import { Table , Input, Spin} from 'antd';
import './rental.scss';
import {FiSearch} from 'react-icons/fi'
import {MdAddCircle}from 'react-icons/md'
import {RiDeleteBin2Fill , RiEdit2Fill} from 'react-icons/ri';
import moment from 'moment';
import CustomDeleteModal from '../custom_components/customDeleteModal';
import { deleteCarProduct, fetchSoldCars, fetchUnverifiedCars } from '../stores/api_calls/carlists';
import { IconButton, PrimaryButton } from '../custom_components/customButton';
import CustomProductView from '../custom_components/customProductView';
import { error, success } from '../contants/snackbars';
import { deleteCompany, fetchRentalClients } from '../stores/api_calls/rentals';
import ClientForm from '../forms/rentals/ClientForm';
import { colorScheme } from '../contants/variables';
import { AccessContext } from "../stores/contexts/accessContext";

const ClientTab = () => {
    const [roles, permissions] = useContext(AccessContext);
    const [tableData,setTableData] = useState([])
    const [isLoading,setIsLoading] = useState(false)
    const [keywordSearch , setKeywordSearch] = useState("")
    const [isEdit , setIsEdit] = useState(false)
    const [showModal , setShowModal] = useState({showDelete:false , showForm:false , data:{}})
    const [tablePagination , setTablePagination] = useState({
        current: 1,
        pageSize: 10,
        total:30,
        defaultPageSize: 10, 
        showSizeChanger: true, 
        pageSizeOptions: ['10', '20', '30']
    })

    const columns = [
        {
            title: 'Company Name',
            dataIndex: 'companyName',
            // sorter: true,
            render: (text ,record) => `${record.company_name}`,
            // width: '20%',
        },
        {
            title: 'Website',
            dataIndex: 'brand',
            render: (text ,record) => `${record.company_website_url ?? "Not Available"}`,
            // width: '20%',
        },
        {
            title: 'Location',
            dataIndex: 'dateCreated',
            render: (text ,record) => `${record.company_location.street}, ${record.company_location.city}, ${record.company_location.region_name}`,
        },
        {
            title: 'Company Hours',
            dataIndex: 'dateCreated',
            render: (text ,record) => (
                <div className='company-hour'>
                    <p>Weekdays</p>
                    <p>Open : {moment(record.company_hours.weekdays.opening_time, "HH:mm:ss").format("LT")}</p>
                    <p>Close : {moment(record.company_hours.weekdays.closing_time, "HH:mm:ss").format("LT")}</p>
                    <hr/>
                    <p>Weekends</p>
                    <p>Open : {moment(record.company_hours.weekends.opening_time, "HH:mm:ss").format("LT")}</p>
                    <p>Close : {moment(record.company_hours.weekends.closing_time, "HH:mm:ss").format("LT")}</p>
                </div>
            ),
        },
        {
            title: 'Contacts Numbers',
            dataIndex: 'dateCreated',
            render: (text ,record) => (
                <div>
                    {record.company_contact_details.phone_number.map((contact , idx)=>
                        <p key={idx}>+{contact}</p>
                    )}
                </div>
            ),
        },
        {
            title: 'Contacts Emails',
            dataIndex: 'dateCreated',
            render: (text ,record) => (
                <div>
                    {record.company_email ? (
                        <p>{record.company_email}</p>
                    ) : (
                        <>
                            {
                                <p>{record.company_contact_details.email_add}</p>
                            }
                            {/* {record.company_contact_details.email_add.map((contact , idx)=>
                                <p key={idx}>{contact}</p>
                            )} */}
                        </>
                    )}
                </div>
            ),
        },
        {
            title:'Actions',
            dataIndex:'actions',
            width:'15%',
            render:(text,record)=>(
                <div className='actions-icons-container'>
                    <IconButton className='icon-button edit' tootTipTitle="Edit Company" Icon={<RiEdit2Fill/>} onClick={()=>onEdit(record)}/>
                    <IconButton className='icon-button delete' tootTipTitle="Delete Company" Icon={<RiDeleteBin2Fill/>} onClick={()=>onDelete(record)}/>
                </div>
            )
        }
    ];

    useEffect(()=>{
        refetchLists()
    },[])

    useEffect(()=>{
        if(tablePagination.current || tablePagination.pageSize){
            refetchLists()
        }
    },[tablePagination.current , tablePagination.pageSize])
    
    const refetchLists = () => {
        const pagination = {
            page:tablePagination.current,
            limit:tablePagination.pageSize,
            keyword:keywordSearch,
            roleIds: ["658e617d29d536069311e793"],
            // roleIds: ["619e5d67e7040000a2007b09"],
            // roleIds: isDealer
            // ? ["619e5d67e7040000a2007b09"]
            // : ["619e5d55e7040000a2007b08"], 
        }

        fetchData(pagination)
    }

    const fetchData = (data) => {

        setIsLoading(true)

        const getRentals = fetchRentalClients(data)

        getRentals.then((res)=>{
            console.log(res.data.data);
            if(res.data.success){
                setTableData(res.data.data)
                setTablePagination({
                    ...tablePagination,
                    total:res.data.meta.pagination.total
                })
                setIsLoading(false)
            }
        }).catch((e)=>{
            error(`Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`)
        })
        
    }

    const onEnter = (e) => {
        if (e.key === "Enter") {
            const pagination = {
                page : 1,
                limit : 10,
                keyword:keywordSearch
            }
            fetchData(pagination)
        }
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTablePagination(pagination)
    };

    const onEdit = (record) => {
        console.log(record);
        setIsEdit(true)
        setShowModal({...showModal , showForm:true , data:record})
    }

    const onDelete = (record) => {
        setShowModal({...showModal , showDelete:true , data:record})
    }

    const onDeleteSubmit = () => {
        const filteredData = tableData.filter(el=>el._id!==showModal.data._id)

        const onDelete = deleteCompany(showModal.data._id)
        onDelete.then((res)=>{
            if(res.data.success){
                success("Rental Company deleted successfully.")
                setTableData(filteredData)
                setShowModal({...showModal , showDelete:false , data:{}})
            }
        }).catch((e)=>{
            error(`${e.response.data.server_response}`)
        })
    }

    return (
        <div>
            <div
                className='search-container'
            >
                <div className='btn-div'>
                    <PrimaryButton
                        Icon={<MdAddCircle/>}
                        label="Add New Company"
                        color={colorScheme.primary}
                        onClick={()=>setShowModal({...showModal , showForm:true})}
                    />
                </div>

                <Input
                    size='large'
                    style={{width:300 , marginLeft:'20px'}}
                    placeholder='Search Record'
                    prefix={<FiSearch/>}
                    onChange={e=>setKeywordSearch(e.target.value)}
                    onKeyPress={onEnter}
                />
                
            </div>

            <div className='scrollable-table-container'>
                <Table
                    columns={columns}
                    rowKey={record => record._id}
                    dataSource={tableData}
                    pagination={tablePagination}
                    loading={{indicator:<Spin size='large'/> , spinning:isLoading}}
                    onChange={handleTableChange}
                    bordered
                    sticky
                />
            </div>

            <ClientForm
                visible={showModal.showForm}
                isEdit={isEdit}
                roles={roles}
                onCancel={()=>{
                    setIsEdit(false)
                    setShowModal({...showModal , showForm:false , data:{}})
                }}
                data={showModal.data}
                refetchLists={refetchLists}
            />
            
            <CustomDeleteModal
                title="Delete Rental Company"
                visible={showModal.showDelete}
                onSubmit={onDeleteSubmit}
                onCancel={()=>setShowModal({...showModal , showDelete:false , data:{}})}
                data={{itemName:`${showModal.data.company_name}` , type:"company"}}
            />
        </div>
    )
}

export default ClientTab