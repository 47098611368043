import axios from "axios";
import Kjur from "../utils/jwt";

const bearerToken = Kjur.encode(
  JSON.parse(localStorage.getItem("userDetails"))
);

let axiosConfig = {
  headers: {
    "Content-Type": "application/json; charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
    "Strict-Transport-Security": "max-age=90",
    Authorization: `Bearer ${bearerToken}`,
  },
};

export const fetchAccessories = async (data) => {
  const obj = {
    page: data.page,
    limit: data.limit,
    sort_by: "desc",
    sort_order: "date_created",
    keyword: data.keyword,
    is_verified: data.is_verified,
    is_sold: data.is_sold,
    is_draft: data.is_draft,
  };
  
  const encodedData = Kjur.encode(obj);

  return await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/admin/car-accessories?token=${encodedData}`,
    axiosConfig
  );
};

export const fetchAccessory = async (id) => {
  const data = {
    id : id
  }

  const encodedData = Kjur.encode(data);

  return await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/admin/car-accessories/show?token=${encodedData}`,
    axiosConfig
  );
};

export const addAccessory = async (payload) => {
  
  const data = {
    // car_workshop_id: payload.car_workshop_id,
    advertised_by: payload.dealerId,
    title: payload.title,
    price: payload.price,
    condition: payload.condition,
    category_id: payload.categoryId,
    accessory_image_id: payload.imagesIds ?? null,
    suitable_for: payload.suitableFor,
    tiktok_video: "",
    video_type: payload.videoType,
    youtube_video: payload.youtubeVideo,
    tiktok_video: payload.tiktokVideo,
    local_video_url: payload.localVideoUrl,
    description: payload.description,
  };

  const encodedData = Kjur.encode(data);

  return await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/admin/car-accessories`,
    { token: encodedData },
    axiosConfig
  );
};

export const editAccessory = async (payload) => {
    const data = {
        // car_workshop_id: payload.car_workshop_id,
        id: payload.id,
        advertised_by: payload.dealerId,
        title: payload.title,
        price: payload.price,
        condition: payload.condition,
        category_id: payload.categoryId,
        accessory_image_id: payload.imagesIds,
        suitable_for: payload.suitableFor,
        tiktok_video: "",
        video_type: payload.videoType,
        youtube_video: payload.youtubeVideo,
        tiktok_video: payload.tiktokVideo,
        local_video_url: payload.localVideoUrl,
        description: payload.description,
        is_verified: payload.isVerified ?? false,
      };
    
      if(!payload.isVerified) {
        delete data.is_verified;
      }
    
      const encodedData = Kjur.encode(data);
    
      return await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/admin/car-accessories/update`,
        { token: encodedData },
        axiosConfig
      );
};

export const deleteAccessory = async (id) => {
  const data = {
    id: id,
  };

  const encodedData = Kjur.encode(data);

  return await axios.delete(
    `${process.env.REACT_APP_API_BASE_URL}/admin/car-accessories/delete?token=${encodedData}`,
    axiosConfig
  );
};

export const fetchCategories = async (section) => {
    const data = {
      keyword: section.keyword,
      page: section.page,
      limit: section.limit,
    };
  
    const encodedData = Kjur.encode(data);
  
    return await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/admin/car-accessory-categories?token=${encodedData}`,
      axiosConfig
    );
  };
  
  export const addCategory = async (name) => {
    const data = {
      name: name,
    };
  
    const encodedData = Kjur.encode(data);
  
    return await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/admin/car-accessory-categories`,
      { token: encodedData },
      axiosConfig
    );
  };
  
  export const editCategory = async (section) => {
    const data = {
      id: section.sectionId,
      name: section.sectionName,
    };
  
    const encodedData = Kjur.encode(data);
  
    return await axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/admin/car-accessory-categories/update`,
      { token: encodedData },
      axiosConfig
    );
  };
  
  export const deleteCategory = async (id) => {
    const data = {
      id: id,
    };
  
    const encodedData = Kjur.encode(data);
  
    return await axios.delete(
      `${process.env.REACT_APP_API_BASE_URL}/admin/car-accessory-categories/delete?token=${encodedData}`,
      axiosConfig
    );
  };