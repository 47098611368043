export const validateProductForm = (data) => {
  if(
    !data.dealerId ||
    !data.carName ||
    !data.carBrand ||
    !data.carModel
  ) {
    return false
  }

  return true;
};

export const validateNewProductForm = (data) => {
  console.log("Data:::: ", data)
  if(
    !data.dealerId ||
    !data.carName ||
    !data.carBrand ||
    !data.carModel ||
    !data.classification ||
    !data.new_condition ||
    !data.vehicleType ||
    !data.engineCap
  ) {
    return false
  }

  return true;

};

export const validateUpload = (selectedFiles, currentImages) => {
  const selectedTotal = selectedFiles.reduce((n, { size }) => n + size, 0);
  const currentTotal = currentImages
    .map((el) => el.metadata)
    .reduce((n, { size }) => n + size, 0);

  const selectedTotalMB = selectedTotal / 1048576;
  const currentTotalMB = currentTotal / 1048576;

  const overAllTotal = selectedTotalMB + currentTotalMB;
  const overImageLength = selectedFiles.length + currentImages.length; 

  // if (overAllTotal <= 10 && overImageLength >= 1 && overImageLength <= 9) {
  if ( overImageLength >= 1 && overImageLength <= 9) {
    return true;
  } else {
    return false;
  }
};

export const validateLimitUpload = (selectedFiles, currentImages) => {

  const overImageLength = selectedFiles.length + currentImages.length;

  if (overImageLength < 9) {
    return true;
  } else {
    return false;
  }
  
};