import { useState } from 'react';
import { MdOutlineFileUpload, MdClose } from 'react-icons/md';
import { FaUpload, FaDownload } from 'react-icons/fa'
import { RiCloseCircleFill, RiEditBoxLine } from 'react-icons/ri';

const CustomFileInput = ({ id, handleUploadImage, imgUrl, onRemoveImage }) => {

    const handleUploadImg = () => {
        var fileupload = document.getElementById(id);
        fileupload.click();
    };

    const validateUrl = (url) => {
        const url_regex =
            /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;

        return url_regex.test(url);
    };

    const styles = {
        imageContainer: {
            height: '200px',
            border: '2px solid #4F4A4A',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            // marginRight: '20px',
            // marginBottom: '20px',
        },
        removeIconWrapper: {
            height: '30px',
            width: '30px',
            background: '#EA3636',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            top: '0%',
            right: '0%',
            cursor: 'pointer',
        },
        removeIcon: {
            color: 'white',
        },
        img: {
            maxHeight: '100%',
            maxWidth: '100%',
            objectPosition: 'center',
            objectFit: 'cover',
        },
        uploadContainer: {
            height: '200px',
            border: '2px dashed #808080',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
        },
        uploadIcon: {
            height: '50px',
            width: '50px',
            color: '#808080',
        }
    }

    return (
        <div>
            <input
                type='file'
                id={id}
                multiple={false}
                onChange={(e) => handleUploadImage(e, id)}
                style={{ display: 'none' }}
                accept=".png, .jpg, .jpeg"
            />
            {imgUrl ? (
                <div style={styles.imageContainer}>
                    {/* <span style={{ position: "absolute", fontSize: 24 }}>Image {id}</span> */}
                    <div style={styles.removeIconWrapper}
                        onClick={() => onRemoveImage(id, imgUrl)}
                    >
                        <RiCloseCircleFill style={styles.removeIcon} />
                    </div>
                    <img style={styles.img} src={validateUrl(imgUrl) ? imgUrl : URL.createObjectURL(imgUrl)} />
                </div>
            ) : (
                <>
                    <div style={styles.uploadContainer} onClick={() => handleUploadImg(id)}>
                        <FaUpload style={styles.uploadIcon} />
                        <p>UPLOAD PHOTO</p>
                    </div>
                </>
            )}
        </div>
    );
};

export default CustomFileInput;
