import { Col, Radio, Row } from "antd";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { success, error } from "../../contants/snackbars";
import { colorScheme } from "../../contants/variables";
import {
  LoadingButton,
  PrimaryButton,
} from "../../custom_components/customButton";
import {
  addBicycle,
  updateBicycle,
} from "../../stores/api_calls/carlists";
import { uploadImage } from "../../stores/api_calls/upload";
import { MobileView, WebView } from "./Views";
import { convertToRaw } from "draft-js";

const PreviewBicycle = (props) => {
  const { isEdit, form, selectedFiles, selectedFile1, selectedFile2 } = props;
  const [view, setView] = useState(0);
  const [requestLoading, setRequestLoading] = useState(false);
  const [localImageUrls, setLocalImageUrls] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const localImg = selectedFiles.map((file) => {
      if(file.url !== "" && typeof file.url === 'string') {
        return file.url;
      }

      if(file.url !== "" && typeof file.url != 'string') {
        return URL.createObjectURL(file.url);
      }
      return file.url
    });
    
    setLocalImageUrls(localImg);
  }, [selectedFiles]);

  const onViewChange = (e) => {
    setView(e.target.value);
  };

  const onSubmit = async () => {
    let updatedForm = {...form};

    const handleUpload = async (file, key) => {
      if (file.file) {
        try {
          const res = await uploadImage(file.file);
          if (res.data.success) {
            return res?.data?.data?.metadata?.image_url || null;
          }
          return null;
        } catch (e) {
          error(e.response.data.server_response);
          return null;
        }
      }
    };

    const uploadAllFiles = async (files) => {
      const waterMark = true;
      const directSeller = form.direct_seller;
      const uploadPromises = files.map((file, index) => uploadImage(file.url, file.orderNo, waterMark, directSeller));
      let uploadedImageIds = [];
      await Promise.all(uploadPromises)
        .then(async (results) => {
          uploadedImageIds = results.map((res) => res.data.data._id);
        })
        .catch((error) => {
            throw error;
        });

      return uploadedImageIds;
    };

    
    // const getDescEditorContent = (content) => {
    //   if(content.hasOwnProperty('blocks')) {
    //     return content;
    //   } else {
    //     const rawDraftContentStateDesc = content ? JSON.stringify(convertToRaw(content.getCurrentContent())) : null;
    //     return JSON.parse(rawDraftContentStateDesc)
    //   }
    // };

    // updatedForm.specs = getDescEditorContent(updatedForm.specsFormatted);
    // updatedForm.feat = JSON.parse(rawDraftContentStateFeat);
    // updatedForm.desc = getDescEditorContent(updatedForm.descFormatted);

    setRequestLoading(true);

    let imagesIds = selectedFiles.filter((file, index) => file.url !== "" && typeof file.url === 'string').map(item => item._id);

    let newUploadFiles = selectedFiles.filter((file, index) => file.url !== "" && typeof file.url != 'string').map(item => item);
    let newUploadedImageIds = await uploadAllFiles(newUploadFiles);
    
    imagesIds = [...imagesIds, ...newUploadedImageIds];

    if(selectedFile1.url !== "") {
      let longAdImageOneUrl = await handleUpload(selectedFile1, "long_ad_image_1");
      if(longAdImageOneUrl) {
        updatedForm = {...updatedForm, long_ad_image_1: longAdImageOneUrl}
      }
    } else {
      updatedForm = {...updatedForm, long_ad_image_1: form.longAdImage1}
    }

    if(selectedFile2.url !== "") {
      let longAdImageTwoUrl = await handleUpload(selectedFile2, "long_ad_image_2");
      if(longAdImageTwoUrl) {
        updatedForm = {...updatedForm, long_ad_image_2: longAdImageTwoUrl}
      }
    } else {
      updatedForm = {...updatedForm, long_ad_image_2: form.longAdImage2}
    }

    const car = isEdit
      ? updateBicycle(updatedForm, imagesIds)
      : addBicycle(updatedForm, imagesIds);

    car
      .then((res) => {
        console.log("Response: ", res)
        success(`Bicycle has been ${isEdit ? "updated" : "added"} Successfully`);
        setRequestLoading(false);
        navigate("/bicycles/verified", { replace: true });
      })
      .catch((e) => {
        error(e.response.data.server_response);
        setRequestLoading(false);
      });
  };

  return (
    <div>
      <Row>
        <Col span={8}>
          <div className="preview-text-container">
            <p className="preview-header">
              Choose the device you want to preview
            </p>
            <Radio.Group onChange={onViewChange} value={view}>
              <Radio value={0}>Web Application</Radio>
              <Radio value={1}>Mobile Application</Radio>
            </Radio.Group>

            <p className="preview-note">
              If you are happy with the result kindly click button below.
            </p>

            <div className="button-container">
              {/* <PrimaryButton className="button" color={colorScheme.secondary} label="Save as Draft" onClick={onSaved}/> */}
              {requestLoading ? (
                <LoadingButton />
              ) : (
                <PrimaryButton
                  className="button"
                  color={colorScheme.primary}
                  label={isEdit ? "Update this Ad" : "Create this Ad"}
                  onClick={onSubmit}
                />
              )}
            </div>
          </div>
        </Col>

        <Col span={16}>
          <div className="preview-container">
            <p style={{ marginBottom: "10px" }}>
              Hover on the screen and scroll to view contents
            </p>
            {!view ? (
              <WebView form={form} localImageUrls={localImageUrls} />
            ) : (
              <MobileView form={form} localImageUrls={localImageUrls} />
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PreviewBicycle;