import { Row, Col, Input, Modal, Select, Checkbox, Radio, Upload, Button } from "antd";
import React, { useState, useEffect, useRef } from "react";
import {
  addAccessory,
  editAccessory,
  fetchCategories,
  fetchAccessory,
} from "../../stores/api_calls/accessories";
import {
  fetchBrands,
} from "../../stores/api_calls/carlists";
import "./styles.scss";
import "../rentals/rentals.scss";
import RentalUploads from "../rentals/RentalUploads";
import { uploadImage, postVideo } from "../../stores/api_calls/upload";
import { error, success } from "../../contants/snackbars";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { RiImageAddFill } from "react-icons/ri";
import { fetchUsers } from "../../stores/api_calls/users";
import {
  validateUpload,
} from "../../stores/helpers/productFormValidation";
import {
  validateAccessoryForm
} from "../../stores/helpers/accessoryFormValidation";
import {
  CancelButton,
  IconButton,
  LoadingButton,
  PrimaryButton,
} from "../../custom_components/customButton";
import { IoMdAddCircleOutline , IoMdCloseCircle} from 'react-icons/io';
import { colorScheme } from '../../contants/variables';
import { useLocation, useNavigate } from 'react-router-dom';
import Kjur from '../../stores/utils/jwt'
import { MdOutlineFileUpload } from "react-icons/md";

const ImageUploadComponent = (props) => {
  const { onChange } = props;
  const fileUploader = useRef(null);

  const uploadImageCallBack = (link) => {
    onChange(link, "auto", "100%", "uploaded image");
  };

  const onSelectFile = (e) => {
    const file = e.target.files[0];
    // const localUrl = URL.createObjectURL(file)

    const upload = uploadImage(file);

    upload
      .then((res) => {
        if (res.data.success) {
          console.log(res.data.data);
          const url = res.data.data.metadata.image_url;
          uploadImageCallBack(url);
        }
      })
      .catch((e) => {
        error(
          `Opps! ${e.response.data.server_response} . Upload Failed. Please try again`
        );
      });
  };

  const handleOpenGallery = (event) => {
    fileUploader.current.click();
  };

  return (
    <div>
      <div className="icon-button-div">
        <IconButton
          className="icon-button upload"
          tootTipTitle="Upload Image"
          Icon={<RiImageAddFill />}
          onClick={handleOpenGallery}
        />
      </div>

      <input
        ref={fileUploader}
        hidden
        type="file"
        name="image-event"
        id="image-event"
        accept="image/*"
        onChange={onSelectFile}
      />
    </div>
  );
};

const AccessoryForm = () => {
  
  const [isEditing, setIsEditing] = useState(false);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [requestLoading, setRequestLoading] = useState(false);
  const [convertedContent, setConvertedContent] = useState(null);
  const [dealers, setDealers] = useState({ loading: false, data: [] });
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [fetchLoading , setFetchLoading] = useState(false);
  const [accessory, setAccessory] = useState({});
  const [videoFile, setVideoFile] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [form, setForm] = useState({
    imageUrls: [],
    dealerId: "",
    title: "",
    price: 0,
    condition: "",
    categoryId: "",
    suitableFor: "",
    description: "",
    videoType: 'tiktok',
    youtubeVideo: "",
    tiktokVideo: "",
    localVideoUrl: null,
    check_verified: true,
    // isVerified: false,
  });

  const handleFieldChange = (name, e) => {
    setForm({ ...form, [name]: e.target.value });
  };

  const onCheckboxChange = (name, e) => {
    setForm({
      ...form,
      [name]: e.target.checked,
    });
  };

  const handleChangeCondition = (value) => {
    setForm({ ...form, condition: value });
  };

  const handleChangeCategory = (value) => {
    setForm({ ...form, categoryId: value });
  };

  const handleChangeBrand = (value) => {
    setForm({ ...form, suitableFor: value });
  };

  const handleDropdownChange = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleRadioChange = (key, e) => {
    setForm((prev) => ({ ...prev, [key]: e.target.value }));
  };

  const handleChangeVideo = ({ videoFile: newVideoList }) => {
    setVideoFile(newVideoList);
  };

  const handleVideoFileUpload = async ({file, onSuccess, onError}) => {
    
    try {
      const res = await postVideo(file);
      
      if (res?.data?.success) {
        onSuccess(null, file);
        setForm({ ...form, localVideoUrl: res?.data?.data?.metadata?.file_url });
        // setVideoFile(res?.data?.metadata?.file_url);
      } else {
        throw new Error('Upload failed');
      }
    } catch (e) {
      onError(e);
      error(
        e.message || 'Upload failed!'
      );
      console.log(e);
    }
  }

  const beforeUploadVideo = async (file) => {
    const isMP4 = file.type === 'video/mp4';

    if (!isMP4) {
      error('Only MP4 files are allowed!');
      setForm({ ...form, localVideoUrl: null });
      setVideoFile([]);
      return Upload.LIST_IGNORE; // Prevent the file from being added to the list
    }

    const isValid = await validateVideoDuration(file);
    if (!isValid) {
      error('Video duration must not exceed 3 minutes!');
      setForm({ ...form, localVideoUrl: null });
      setVideoFile([]);
      return false;
    }

    const previewURL = URL.createObjectURL(file);
    setForm({ ...form, localVideoUrl: previewURL });

    return true; // Allow the upload
  }

  const validateVideoDuration = (file) => {
    return new Promise((resolve) => {
      const video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = () => {
        URL.revokeObjectURL(video.src);
        resolve(video.duration <= 180); // Check if duration is <= 60 seconds
      };

      video.onerror = () => {
        error('Unable to load video metadata.');
        resolve(false);
      };

      video.src = URL.createObjectURL(file);
    });
  };

  useEffect(() => {
    handleFetchCategories();
    handleFetchBrands();
    getDealers();
  }, []);

  // useEffect(() => {
  //   if (isEdit) {
  //     console.log(data);
  //     setForm({
  //       ...form,
  //       id: data.id,
  //       imageUrls: data.images,
  //       dealerId: data.advertised_by.$oid,
  //       fullname: data.advertisement_contact_details?.name,
  //       title: data.title,
  //       price: data.price,
  //       condition: data.condition,
  //       categoryId: data?.category_detail?._id ?? null,
  //       suitableFor: data?.suitable_for_detail?._id ?? null,
  //       // description: data.description,
  //     });
  //     console.log(data.description);
  //   }
  // }, [isEdit]);

  useEffect(() => {
    if (location.pathname.includes("/edit")) {
      setIsEditing(true);
      const parsedData = Kjur.decode(params.get("token"));
      // console.log("PARAMS" , parsedData)
      setFetchLoading(true)

      const getAccessory = fetchAccessory(parsedData?.id);
      
      getAccessory.then((res) => {
        
        if(res.data.success){
          setAccessory(res.data.data)
          setFetchLoading(false)
        }
      }).catch((e)=>console.log(e))
    }
  }, []);

  useEffect(()=>{
        
    if(!fetchLoading && isEditing) {

        const parsedData = Kjur.decode(params.get('token'))
        // console.log(accessory);

        setForm({
          ...form,
          id: parsedData.id,
          imageUrls: parsedData.images,
          dealerId: parsedData.advertised_by.$oid,
          fullname: parsedData.advertisement_contact_details?.name,
          title: parsedData.title,
          price: parsedData.price,
          condition: parsedData.condition,
          categoryId: parsedData?.category_detail?._id ?? null,
          suitableFor: parsedData?.suitable_for != null ? parsedData?.suitable_for_detail?._id : null,
          description: accessory?.description,
          videoType: parsedData?.video_type != null ? parsedData?.video_type : 'tiktok',
          youtubeVideo: parsedData?.youtube_video,
          tiktokVideo: parsedData?.tiktok_video,
          localVideoUrl: parsedData?.local_video_url,
          check_verified: parsedData?.is_verified,
          isVerified: parsedData?.is_verified,
        })
    }
  }, [fetchLoading])

  useEffect(() => {
    if ((selectedFiles || form.imageUrls)) {
      const value = validateUpload(selectedFiles, form.imageUrls);
      if (value) {
        setEnableSubmit(true);
      } else {
        setEnableSubmit(false);
      }
    }
  }, [selectedFiles, form.imageUrls]);

  // const refetchLists = () => {
  //   const pagination = {
  //     page: tablePagination.current,
  //     limit: tablePagination.pageSize,
  //     keyword: "",
  //     latest_car_subscribe: true,
  //   };
  //   fetchData(pagination);
  // };

  const getDealers = () => {
    setDealers({ ...dealers, loading: true });
    const pagination = {
      page: 1,
      limit: 100000,
      keyword: "",
      // roleIds: roles.filter((el)=>roleNames.includes(el.name)).map((el)=>el._id),
      roleIds: ["619e5d55e7040000a2007b08", "619e5d67e7040000a2007b09"],
      isVerified: true,
    };

    const users = fetchUsers(pagination);
    users
      .then((res) => {
        // console.log(res.data);
        if (res.data.success) {
          const responseUserData = res.data?.data;
          const accessoriesUser = responseUserData.filter((item)=> item.is_accessories_user == true);
          setDealers({ ...dealers, loading: false, data: accessoriesUser });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  
  const handleFetchCategories = () => {
    const filter = {
      keyword: "",
      page: 1,
      limit: 500,
    };
    const getCategories = fetchCategories(filter);

    getCategories
      .then((res) => {
        if (res.data.success) {
          // const transformData = res.data.data.map((item) => ({
          //   ...item,
          //   name: item.name,
          //   id: item._id,
          // }));
          setCategories(res.data.data);
        }
      })
      .catch((e) => {
        error(
          `Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`
        );
      });
  };

  const handleFetchBrands = () => {
    const filter = {
      keyword: "",
      page: 1,
      limit: 500,
    };
    const getBrands = fetchBrands(filter);

    getBrands
      .then((res) => {
        if (res.data.success) {
          const transformData = res.data.data.map((item) => ({
            ...item,
            name: item.name,
            id: item._id,
          }));
          const transformBrand = [
            {
              name: "All Model",
              id: null,
            },
            ...transformData
          ];
          setBrands(transformBrand);
        }
      })
      .catch((e) => {
        error(
          `Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`
        );
      });
  };

  const onSubmit = () => {
    setRequestLoading(true);

    // const description = convertToRaw(editorState.getCurrentContent());
    const imagesIds = isEditing
      ? form.imageUrls.map((img) => img._id["$oid"])
      : [];

    if (selectedFiles.length) {
      selectedFiles.forEach((file, index) => {
        const upload = uploadImage(file, index + 1);

        upload.then((res) => {
          if (res.data.success) {
            imagesIds.push(res.data.data._id);
          }

          if (
            imagesIds.length - form.imageUrls.length ===
            selectedFiles.length
          ) {
            const createAccessory = isEditing
              ? editAccessory({
                  ...form,
                  imagesIds,
                  // description,
                  // car_workshop_id: data.id,
                })
              : addAccessory({
                  ...form,
                  imagesIds,
                  // description,
                  // car_workshop_id: data.id,
                });
            createAccessory
              .then((res) => {
                console.log(res.data);
                if (res?.data?.success) {
                  success(`Bike accessory ${isEditing ? "updated" : "added"} successfully`);
                  setRequestLoading(false);
                  navigate("../bike-accessories/verified", { replace: true });
                  // resetForm();
                  // onClose();
                  // refetchLists();
                }
              })
              .catch((e) => {
                setRequestLoading(false);
                error(e.response.data.message);
              });
          }
        });
      });
    } else {
      const createAccessory = isEditing
              ? editAccessory({
                  ...form,
                  // description,
                  // car_workshop_id: data.id,
                })
              : addAccessory({
                  ...form,
                  // description,
                  // car_workshop_id: data.id,
                });
            createAccessory
              .then((res) => {
                console.log(res.data);
                if (res?.data?.success) {
                  success(
                    `Bike accessory ${isEditing ? "updated" : "added"} successfully`
                  );
                  setRequestLoading(false);
                  navigate("../bike-accessories/verified", { replace: true });
                  // resetForm();
                  // onClose();
                  // refetchLists();
                }
              })
              .catch((e) => {
                console.log(e.response);
                setRequestLoading(false);
                error(e.response.data.message);
              });
    }
  };

  const handleEditorChange = (state) => {
    setEditorState(state);
  };

  const resetForm = () => {
    setForm({ imageUrls: [] });
    // setForm({ imageUrls: [], car_workshop_id: data.id });
  };

  const onClose = () => {
    setForm({
      ...form,
      imageUrls: [],
      dealerId: "",
      fullname: null,
      title: "",
      price: "",
      condition: "",
      categoryId: [],
      suitableFor: [],
      description: "",
      videoType: 'youtube',
      youtubeVideo: "",
      tiktokVideo: "",
      localVideoUrl: null,
    });

    onCancel();
  };

  return (
    <div className="accessory-form-container">
      <div className={`table-title-icon ${!isEditing ? "add" : "edit"}`}>
        <IoMdAddCircleOutline />
      </div>
      <p className={`table-title-text ${!isEditing ? "add" : "edit"}`}>
        {!isEditing ? "Create Accessory" : `Edit Accessory`}
      </p>

      {/* <div className="form-container"> */}

      {/* <div> */}
          <Row>
            <Col span={12}>
              <div className="form-fields">
                <p className="form-field-label">
                  Dealer Name <span>*</span> :{" "}
                </p>
                <Select
                  showSearch
                  value={form.fullname || undefined}
                  loading={dealers.loading}
                  onChange={(value) => handleDropdownChange("dealerId", value)}
                  style={{ width: "100%" }}
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {dealers.data.map((el, i) => (
                    <Select.Option key={i} value={el._id}>
                      {`${el.user_first_name} ${el.user_last_name}`}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </Col>
            <Col span={12}>
              <div className="form-fields">
                <p className="form-field-label">
                  Accessory Name <span>*</span> :{" "}
                </p>
                <Input
                  value={form.title}
                  onChange={(text) => handleFieldChange("title", text)}
                  placeholder="Accessory Name"
                />
              </div>
            </Col>
          </Row>
          
          <Row>
            <Col span={12}>
              <div className="form-fields">
                <p className="form-field-label">
                  Price ($)<span>*</span> :{" "}
                </p>
                <Input
                  value={form.price}
                  onChange={(text) => handleFieldChange("price", text)}
                  placeholder="Price"
                />
              </div>
            </Col>
            <Col span={12}>
              <div className="form-fields">
                <p className="form-field-label">
                  Condition <span>*</span> :
                </p>
                <Select
                  style={{
                    width: "100%",
                  }}
                  value={form.condition}
                  placeholder="Select condition"
                  onChange={handleChangeCondition}
                  // onChange={(val) => handleChangeCondition(val)}
                  optionLabelProp="label"
                  options={[
                    { label: "Brand New", value: "Brand New" },
                    { label: "Excellent", value: "Excellent" },
                    { label: "Very Good", value: "Very Good" },
                    { label: "Good", value: "Good" },
                    { label: "Fair", value: "Fair" },
                    { label: "Poor", value: "Poor" },
                  ]}
                  // optionRender={(option) => <Space>{option.data.label}</Space>}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <div className="form-fields">
                <p className="form-field-label">
                  Category <span></span> :{" "}
                </p>
                <Select
                  showSearch
                  allowClear
                  value={form.categoryId || undefined}
                  // loading={categories.loading}
                  onChange={(value) => handleDropdownChange("categoryId", value)}
                  style={{ width: "100%" }}
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {categories.map((el, i) => (
                    <Select.Option key={i} value={el.id}>
                      {el.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </Col>
            <Col span={12}>
              <div className="form-fields">
                <p className="form-field-label">
                  Suitable for <span></span> :{" "}
                </p>
                <Select
                  showSearch
                  allowClear
                  style={{width: "100%",}}
                  value={form.suitableFor}
                  placeholder="select brands"
                  onChange={handleChangeBrand}
                  optionLabelProp="label"
                  options={brands.map(brand => ({label: brand.name, value: brand.id}))}
                />
              </div>
            </Col>
          </Row>

          <Row>
            
            <Col span={12}>
              <div className="form-fields">
                <div style={{marginBottom: 10,}}>
                  <Radio.Group 
                    onChange={(e) => handleRadioChange("videoType", e)} 
                    value={form.videoType}
                  >
                    <Radio value="tiktok">Tiktok</Radio>
                    <Radio value="youtube">Youtube</Radio>
                    <Radio value="local">Local Drive</Radio>
                  </Radio.Group>
                </div>

                {form?.videoType == "tiktok" ? (
                  <>
                    {/* <p className="form-field-label">Video Link : </p> */}
                    <Input
                      value={form.tiktokVideo}
                      onChange={(text) => handleFieldChange("tiktokVideo", text)}
                      placeholder="Tiktok Video Link - Optional"
                    />
                    <p className="form-field-label" style={{color:colorScheme.red, fontSize:12, marginTop:3}}>Video duration must not exceed 3 minutes!</p>
                  </>
                ): form?.videoType == "youtube" ? (
                  <>
                    {/* <p className="form-field-label">Video Link : </p> */}
                    <Input
                      value={form.youtubeVideo}
                      onChange={(text) => handleFieldChange("youtubeVideo", text)}
                      placeholder="Youtube Video Link - Optional"
                    />
                    <p className="form-field-label" style={{color:colorScheme.red, fontSize:12, marginTop:3}}>Video duration must not exceed 3 minutes!</p>
                  </>
                ):(
                  <>
                    <div style={{display:'flex', justifyContent:'space-between'}}>
                      <div>
                      <Upload
                        accept="video/mp4"
                        onChange={handleChangeVideo}
                        customRequest={handleVideoFileUpload}
                        beforeUpload={beforeUploadVideo}
                        fileList={videoFile}
                      >
                        <Button type="primary">
                          <MdOutlineFileUpload style={{marginRight:10}} /> <span>Upload Video</span>
                        </Button>
                      </Upload>
                      <p className="form-field-label" style={{color:colorScheme.red, fontSize:12, marginTop:3}}>Video duration must not exceed 3 minutes!</p>
                      </div>

                      {form.localVideoUrl && (
                        <div style={{ marginLeft: '20px' }}>
                          <video
                            src={form.localVideoUrl}
                            controls
                            width="150"
                            style={{ border: '1px solid #ccc', borderRadius: '4px' }}
                          />
                        </div>
                      )}
                    </div>
                  </>
                )}
                
              </div>
            </Col>

            {isEditing && !form.check_verified && (
              <Col span={12}>
                <div className="form-fields" style={{marginTop:25}}>
                  <Checkbox
                    valuePropName="checked"
                    checked={form.isVerified}
                    onChange={(e) => onCheckboxChange("isVerified", e)}
                  >
                    Is Verified
                  </Checkbox>
                </div>
              </Col>
            )}
            
          </Row>

          <Row>
            <Col span={24}>
              <div className="form-fields">
                <p className="form-field-label">
                  Description <span></span> :{" "}
                </p>
                <Input.TextArea
                  rows={5}
                  value={form.description}
                  onChange={(text) => handleFieldChange("description", text)}
                />
              </div>
            </Col>
          </Row>


          <div
                className='upload-header'
            >
                <div className='header-div'>
                    <p className='header-text'>Upload Pictures <span>*</span> :{" "}</p>
                    <p className='sub-header-text'>
                    Upload requirements: You can upload upto 9 images. Total image file size should not exceed <span style={{color:'red',fontWeight:'bold'}}>10MB</span>. 
                    <span style={{color:'red',fontWeight:'bold'}}> Please noted that only PNG & JPG images are allowed. Image dimension (600 width x 400 height)pixels.</span>
                    </p>
                </div>
          </div>
          <div className="rental-form-container">
            <RentalUploads
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              form={form}
              setForm={setForm}
              isEdit={isEditing}
            />
          </div>
        {/* </div> */}
        
      {/* </div> */}

      <div className="btn-div">
        <div className="btn">
          {requestLoading ? (
            <LoadingButton />
          ) : (
            <PrimaryButton
              label={isEditing ? "Update Accessory" : "Create Accessory"}
              color={colorScheme.primary}
              onClick={() => onSubmit()}
              disabled={false}
            />
          )}
        </div>
      </div>
    </div>

    // <div>
    //   <Modal
    //     className="formModal"
    //     title={`Add accessory`}
    //     visible={visible}
    //     onCancel={onClose}
    //     width={800}
    //     onOk={handleSubmit}
    //     okText="Submit"
    //     confirmLoading={requestLoading}
    //     okButtonProps={{ disabled: !validateAccessoryForm(form) || !enableSubmit }}
    //   >
    //     <div>
    //       <Row gutter={[20, 20]}>
    //         <Col span={24}>
    //           <div className="form-container">
    //             <p className="form-label">
    //               Dealer Name <span>*</span> :{" "}
    //             </p>
    //             <Select
    //               showSearch
    //               value={form.fullname || undefined}
    //               loading={dealers.loading}
    //               onChange={(value) => handleDropdownChange("dealerId", value)}
    //               style={{ width: "100%" }}
    //               placeholder="Search to Select"
    //               optionFilterProp="children"
    //               filterOption={(input, option) =>
    //                 option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    //               }
    //             >
    //               {dealers.data.map((el, i) => (
    //                 <Select.Option key={i} value={el._id}>
    //                   {`${el.user_first_name} ${el.user_last_name}`}
    //                 </Select.Option>
    //               ))}
    //             </Select>
    //           </div>
    //           <div className="form-container">
    //             <p className="form-label">
    //               Accessory Name <span>*</span> :{" "}
    //             </p>
    //             <Input
    //               value={form.title}
    //               onChange={(text) => handleFieldChange("title", text)}
    //               placeholder="Accessory Name"
    //             />
    //           </div>
    //           <div className="form-container">
    //             <p className="form-label">
    //               Price ($)<span>*</span> :{" "}
    //             </p>
    //             <Input
    //               value={form.price}
    //               onChange={(text) => handleFieldChange("price", text)}
    //               placeholder="Price"
    //             />
    //           </div>
    //           <div className="form-container">
    //             <p className="form-label">
    //               Condition <span>*</span> :
    //             </p>
    //             <Select
    //               style={{
    //                 width: "100%",
    //               }}
    //               value={form.condition}
    //               placeholder="Select condition"
    //               onChange={handleChangeCondition}
    //               // onChange={(val) => handleChangeCondition(val)}
    //               optionLabelProp="label"
    //               options={[
    //                 { label: "Brand New", value: "Brand New" },
    //                 { label: "Excellent", value: "Excellent" },
    //                 { label: "Very Good", value: "Very Good" },
    //                 { label: "Good", value: "Good" },
    //                 { label: "Fair", value: "Fair" },
    //                 { label: "Poor", value: "Poor" },
    //               ]}
    //               // optionRender={(option) => <Space>{option.data.label}</Space>}
    //             />
    //           </div>

    //           <div className="form-container">
    //             <p className="form-label">
    //               Category <span></span> :{" "}
    //             </p>
    //             <Select
    //               style={{
    //                 width: "100%",
    //               }}
    //               value={form.categoryId}
    //               placeholder="select Category"
    //               onChange={handleChangeCategory}
    //               // onChange={(val) => handleChangeCategory(val)}
    //               optionLabelProp="label"
    //               // options={categories}
    //               options={categories.map(category => ({label: category.name, value: category.id}))} 
    //               // optionRender={(option) => <Space>{option.data.name}</Space>}
    //             />
    //           </div>

    //           <div className="form-container">
    //             <p className="form-label">
    //               Suitable for <span></span> :{" "}
    //             </p>
    //             <Select
    //               style={{
    //                 width: "100%",
    //               }}
    //               value={form.suitableFor}
    //               placeholder="select brands"
    //               onChange={handleChangeBrand}
    //               // onChange={(val) => handleChangeBrand(val)}
    //               optionLabelProp="label"
    //               // options={brands}
    //               options={brands.map(brand => ({label: brand.name, value: brand._id}))} 
    //               // optionRender={(option) => <Space>{option.data.name}</Space>}
    //             />
    //           </div>

    //           {/* <div className="form-fields">
    //             <p className="form-field-label">
    //               Description <span></span> :{" "}
    //             </p>
    //             <Input.TextArea
    //               rows={8}
    //               value={form.description}
    //               onChange={(text) => handleFieldChange("description", text)}
    //             />
    //           </div> */}

    //           <div className="form-fields">
    //             <p className="form-field-label">
    //               Description <span></span> :{" "}
    //             </p>
    //           </div>

    //           <div className="editor-container">
    //             <Editor
    //               editorState={editorState}
    //               // defaultContentState={contentState}
    //               // onContentStateChange={setContentState}
    //               onEditorStateChange={handleEditorChange}
    //               wrapperClassName="wrapper-class"
    //               editorClassName="editor-class"
    //               toolbarClassName="toolbar-class"
    //               toolbar={{
    //                 inline: { inDropdown: false },
    //                 list: { inDropdown: true },
    //                 textAlign: { inDropdown: true },
    //                 link: { inDropdown: true },
    //                 history: { inDropdown: true },
    //                 // image: {
    //                 //   component: ImageUploadComponent,
    //                 //   previewImage: true,
    //                 //   uploadEnabled: true,
    //                 //   alt: { present: true, mandatory: true },
    //                 // },
    //               }}
    //             />
    //           </div>
    //         </Col>
    //       </Row>


    //       <div
    //             className='upload-header'
    //         >
    //             <div className='header-div'>
    //                 <p className='header-text'>Upload Pictures <span>*</span> :{" "}</p>
    //                 <p className='sub-header-text'>
    //                 Upload requirements: You can upload upto 9 images. Total image file size should not exceed <span style={{color:'red',fontWeight:'bold'}}>10MB</span>. 
    //                 <span style={{color:'red',fontWeight:'bold'}}> Please noted that only PNG & JPG images are allowed. Image dimension (600 width x 400 height)pixels.</span>
    //                 </p>
    //             </div>
    //       </div>
    //       <div className="rental-form-container">
    //         <RentalUploads
    //           selectedFiles={selectedFiles}
    //           setSelectedFiles={setSelectedFiles}
    //           form={form}
    //           setForm={setForm}
    //           isEdit={isEdit}
    //         />
    //       </div>
    //     </div>
    //   </Modal>
    // </div>
  );
};

export default AccessoryForm;
