import { Col, Row } from 'antd';
import React, { useState, useEffect, useRef } from 'react'
import { FaUpload, FaDownload } from 'react-icons/fa'
import { LoadingButton, PrimaryButton } from '../../custom_components/customButton';
import { colorScheme } from '../../contants/variables';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import JSZipUtils from 'jszip-utils';
import { download, downloadBicycleImages } from '../../stores/api_calls/upload';
import * as FileDownload from 'js-file-download'
import axios from 'axios';
import CustomFileInput from '../CustomFileInput';
import Validator from '../../stores/utils/validator';

const UploadBicycleImages = (props) => {

    const { isEdit, selectedFiles, setSelectedFiles, form, setForm, noOfImages } = props;
    const [downloading, setDownloading] = useState(false)
    const [enableUploader, setEnableUploader] = useState(true);

    const handleUploadImage = (e, id) => {
        if (e.target.files.length) {
            const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];  // Define the allowed file types
            const allowedExtensions = ['jpg', 'jpeg', 'png'];  // Define the allowed file extensions
            const maxSizeInMB = 10; // Maximum file size in megabytes

            const file = e.target.files[0];
            const { value, error } = new Validator(file).ImageFileType(allowedTypes).ImageFileExtension(allowedExtensions).FileSize(maxSizeInMB);
            if (error) {
                return;
            }

            let updatedFiles = [...selectedFiles];
            const updatedIndex = updatedFiles.findIndex(image => image.imageIndex === id);
            if (updatedIndex !== -1) {
                updatedFiles[updatedIndex] = { ...updatedFiles[updatedIndex], _id: "", url: file };
            }

            setSelectedFiles(updatedFiles);

        }
    };

    const onRemoveImage = (val, imgUrl) => {
        let updatedFiles = [...selectedFiles];
        const updatedIndex = updatedFiles.findIndex(image => image.imageIndex === val);
        if (updatedIndex !== -1) {
            updatedFiles[updatedIndex] = { ...updatedFiles[updatedIndex], _id: "", url: "" };
        }

        setSelectedFiles(updatedFiles);
    };

    const handleDownload = () => {
        setDownloading(true)
        const dl = downloadBicycleImages(form.id)
        dl.then((response) => {
            console.log("Download Response: ", response)
            if (response) {
                FileDownload(response.data, `${form.product_name.replaceAll(" ", "_")}.zip`);
            }
            setDownloading(false);
        }).catch(e => {
            console.log(e)
            setDownloading(false);
        })

        // const zip = JSZip();
        // var count = 0;
        // var zipFilename = `${form.carName.replaceAll(" ","")}.zip`;  
        // urls.forEach(function (url, i) {
        //     var filename = `${form.carName.replaceAll(" ","")}_${i+1}.png`;
        //     //   console.log(filename)
        //     // var cors_api_host = 'crossorigin.me/';
        //     var cors_api_host = 'cors-anywhere.herokuapp.com';
        //     var cors_api_url = `https://${cors_api_host}/${url}`;

        //     JSZipUtils.getBinaryContent(url, function (err, data) {
        //         if (err) {
        //         throw err;
        //         }
        //         zip.file(filename, data, { binary: true });
        //         count++;
        //         if (count == urls.length) {
        //         zip.generateAsync({ type: 'blob' }).then(function (content) {
        //             FileSaver.saveAs(content, zipFilename);
        //         });
        //         }
        //     });
        // });
    }

    return (
        <div>
            <div
                className='upload-header'
            >
                <div className='header-div'>
                    <p className='header-text'>Upload Pictures</p>
                    <p className='sub-header-text'>
                        Upload requirements: You can upload upto 9 images. Total image file size should not exceed <span style={{ color: 'red', fontWeight: 'bold' }}>10MB </span>
                        and it's dimension should not exceed (4800 x 3600)pixels.
                        <span style={{ color: 'red', fontWeight: 'bold' }}> Please noted that only PNG & JPG images are allowed.</span>
                    </p>
                </div>
            </div>
            {isEdit ?
                <div>
                    <div
                        className='upload-header'
                    >
                        <div className='header-div'>
                            <p className='header-text'>Upload Images</p>
                        </div>
                        {isEdit ?
                            <div className='download-div'>
                                {downloading ?
                                    <LoadingButton label="Downloading Images" />
                                    : <PrimaryButton onClick={handleDownload} color={colorScheme.green} label="Download All Image" Icon={<FaDownload style={{ marginRight: '10px' }} />} />
                                }
                            </div>
                            : null}

                    </div>
                </div>
                : null
            }
            {/* {error.isError ? <p className='upload-error-text'>{error.message}</p> : ""} */}

            <Row gutter={[20, 20]}>
                {
                    selectedFiles.length > 0 &&
                    selectedFiles.map(({ imageIndex, url }) =>
                        <Col span={8} key={imageIndex}>
                            <CustomFileInput
                                id={imageIndex}
                                handleUploadImage={handleUploadImage}
                                imgUrl={url}
                                onRemoveImage={onRemoveImage}
                            />
                        </Col>
                    )
                }
            </Row>
        </div>
    )
}

export default UploadBicycleImages
