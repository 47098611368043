import React , {useState , useEffect , useContext} from 'react'
import { Table , Input, Spin, Tooltip} from 'antd';
import './user.scss'
import {FiSearch} from 'react-icons/fi'
import {RiEye2Line , RiDeleteBin2Fill , RiEdit2Fill} from 'react-icons/ri'
import moment from 'moment'
import {FaUsersCog} from 'react-icons/fa'
import CustomDeleteModal from '../custom_components/customDeleteModal';
import { IconButton, PrimaryButton } from '../custom_components/customButton';
import {MdAddCircle, MdDesktopAccessDisabled, MdOutlineDesktopWindows} from 'react-icons/md';
import UserForm from '../forms/users/UserForm';
import { colorScheme } from '../contants/variables';
import { deleteUser, fetchUsers, updateAccountStatus } from '../stores/api_calls/users';
import { AccessContext } from '../stores/contexts/accessContext';
import { UserContext } from '../stores/contexts/userContext';
import { generatePermissionName } from '../stores/helpers/userHelpers';
import { ChipsWithTooltips } from '../custom_components/chips';
import { error, success } from '../contants/snackbars';
import ConfirmActivationModal from '../custom_components/ConfirmActivationModal';


const UserLists = () => {
    const [roles, permissions] = useContext(AccessContext)
    const [userConfig] = useContext(UserContext)
    const [tableData,setTableData] = useState([])
    const [isLoading,setIsLoading] = useState(false)
    const [isEdit , setIsEdit] = useState(false)
    const [keywordSearch , setKeywordSearch] = useState("")
    const [showModal , setShowModal] = useState({showDelete:false , showForm:false , data:{}})
    const [tablePagination , setTablePagination] = useState({
        current: 1,
        pageSize: 10,
        total:36,
        defaultPageSize: 10, 
        showSizeChanger: true, 
        pageSizeOptions: ['10', '20', '30']
    })
    // const roleNames = ["Admin" , "Super Admin"]
    const columns = [
      {
        title: "Full Name",
        dataIndex: "userName",
        // sorter: true,
        render: (text, record) => (
          <div className="user-name-cont">
            <Tooltip title={record.is_logged_in ? "Online" : "Offline"}>
              <div className={record.is_logged_in ? "online" : "offline"}></div>
            </Tooltip>
            <span>
              {record.user_first_name} {record.user_last_name}
            </span>
          </div>
        ),
        // width: '20%',
      },
      {
        title: "Email",
        dataIndex: "email",
        render: (text, record) =>
          `${record.user_contact_emails
            ?.filter((el) => el.is_active === true)
            .map((el) => el.email)}`,
        // width: '30%',
      },
      {
        title: "Contact Number",
        dataIndex: "contactNumber",
        render: (text, record) =>
          `${record.user_contact_numbers
            ?.filter((el) => el.is_active === true)
            .map((el) => el.number)}`,
      },
      {
        title: "User Type",
        dataIndex: "userType",
        render: (text, record) => (
          <div
            className="user-role-div"
            style={{
              background:
                record.user_role === "Super Admin"
                  ? colorScheme.tertiary
                  : colorScheme.green,
            }}
          >
            <p>{record.user_role}</p>
          </div>
        ),
      },
      {
        title: "Permissions",
        dataIndex: "permissions",
        width: "20%",
        render: (text, record) => (
          <ChipsWithTooltips dataArray={record.user_permissions} />
        ),
      },
      {
        title: "Actions",
        dataIndex: "actions",
        width: "10%",
        render: (text, record) =>
          userConfig.userDetails.role_details.name === "Super Admin" ? (
            <div className="actions-icons-container">
              <IconButton
                className="icon-button edit"
                tootTipTitle="Edit User"
                Icon={<RiEdit2Fill />}
                onClick={() => onOpenForm("edit", record)}
              />
              <IconButton
                className="icon-button delete"
                tootTipTitle="Delete User"
                Icon={<RiDeleteBin2Fill />}
                onClick={() => onDelete(record)}
              />
              <IconButton
                className={"icon-button delete"}
                tootTipTitle={
                  record.is_active ? "Deactivate Account" : "Activate Account"
                }
                Icon={
                  record.is_active ? (
                    <MdOutlineDesktopWindows color="green" />
                  ) : (
                    <MdDesktopAccessDisabled />
                  )
                }
                onClick={() => onConfirm(record)}
              />
            </div>
          ) : null,
      },
    ];

    useEffect(()=>{
        refetchLists()
    },[])

    useEffect(()=>{
        if(tablePagination.current || tablePagination.pageSize){
            refetchLists()
        }
    },[tablePagination.current , tablePagination.pageSize])

    const refetchLists = () => {
        

        const pagination = {
            page:tablePagination.current,
            limit:tablePagination.pageSize,
            keyword:keywordSearch,
            // roleIds: roles.filter((el)=>roleNames.includes(el.name)).map((el)=>el._id),
            roleIds:['619e5d86e7040000a2007b0a', '619e5d8fe7040000a2007b0b'],
            isVerified: true
        }
        fetchData(pagination)
    }

    const fetchData = (data) => {
        setIsLoading(true)
        if(data){
            const users = fetchUsers(data)
            users.then((res)=>{
                if(res.data.success){
                    setTableData(res.data.data)
                    setIsLoading(false)
                    setTablePagination({
                        ...tablePagination , 
                        total:res.data.meta.pagination.total
                    })
                }
            }).catch((e)=>{
                console.log(e)
            })
        }
    }

    const onEnter = (e) => {
        if (e.key === "Enter") {
            // const roleNames = ["Admin" , "Super Admin"]
            const pagination = {
                page:1,
                limit:10,
                keyword:keywordSearch,
                // roleIds: roles.filter((el)=>roleNames.includes(el.name)).map((el)=>el._id),
                roleIds:['619e5d86e7040000a2007b0a', '619e5d8fe7040000a2007b0b'],
                isVerified: true
            }

            fetchData(pagination)
        }
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTablePagination(pagination)
    };

    const onDelete = (record) => {
        setShowModal({...showModal , showDelete:true , data:record})
    }

    const onConfirm = (record) => {
      setShowModal({...showModal , showConfirm:true , data:record})
    }

    const onDeleteSubmit = () => {
        const removeUser = deleteUser(showModal.data._id)

        removeUser.then((res)=>{
            if(res.data.success){
                success("User Deleted Successfully")
                setShowModal({...showModal , showDelete:false , data:{}})
                refetchLists()
            }
        }).catch((e)=>{
            error(`Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`)
        })
        
    }

    const onSubmitConfirm = () => {
      setIsLoading(true);
      const confirmResponse = updateAccountStatus(showModal.data._id);
  
      confirmResponse
        .then((res) => {
          if (res.data.success) {
            setIsLoading(false);
            success("Account status has been updated successfully");
            setShowModal({ ...showModal, showConfirm: false, data: {} });
            refetchLists();
          }
        })
        .catch((e) => {
          setIsLoading(false);
          error(
            `Opps! ${e.response.data.server_response} . Something went wrong!`
          );
        });
    };
    
    const onOpenForm = (action , record) => {
        if(action==="add"){
            setIsEdit(false)
            setShowModal({...showModal , showForm:true})
        }else{
            setIsEdit(true)
            setShowModal({...showModal , showForm:true , data:record})
        }
    }

    const onSubmitForm = () => {
        setShowModal({...showModal , showForm:false , data:{}})
    }

    return (
        <div className='user-table-container'>
            <div className='table-title-icon primary'>
                <FaUsersCog/>
            </div>
            <p className='table-title-text primary'>User Lists</p>

            <div
                className='search-container'
            >
                <div className='add-button-container'>
                    <PrimaryButton Icon={<MdAddCircle/>} color={colorScheme.primary} label="Add New User" onClick={()=>onOpenForm('add')}/>
                </div>

                <Input
                    style={{width:300}}
                    size='large'
                    placeholder='Search Record'
                    prefix={<FiSearch/>}
                    onChange={e=>setKeywordSearch(e.target.value)}
                    onKeyPress={onEnter}
                />
            </div>
            
            <div className='scrollable-table-container'>
                <Table
                    columns={columns}
                    rowKey={record => record._id}
                    dataSource={tableData}
                    pagination={tablePagination}
                    loading={{indicator:<Spin size='large'/> , spinning:isLoading}}
                    onChange={handleTableChange}
                    bordered
                    sticky
                />
            </div>
            {
              showModal.showForm &&
              <UserForm
                  isEdit={isEdit}
                  visible={showModal.showForm}
                  refetchLists={refetchLists}
                  roles={roles}
                  permissions={permissions}
                  onSubmit={onSubmitForm}
                  onCancel={()=>{
                      setShowModal({...showModal , showForm:false , data:{}})
                      setIsEdit(false)
                  }}
                  data={showModal.data}
              />
            }
            
            <CustomDeleteModal
                title="Delete User"
                visible={showModal.showDelete}
                onSubmit={onDeleteSubmit}
                onCancel={()=>setShowModal({...showModal , showDelete:false , data:{}})}
                data={{itemName:`${showModal.data.user_fname} ${showModal.data.user_lname}` , type:"user"}}
            />

            <ConfirmActivationModal
              loading={isLoading}
              title="Account Confirmation"
              visible={showModal.showConfirm}
              onSubmit={onSubmitConfirm}
              onCancel={() =>
                setShowModal({ ...showModal, showConfirm: false, data: {} })
              }
              data={{
                itemName: `${showModal.data.user_first_name} ${showModal.data.user_last_name}`,
                type: "dealer / advertiser",
              }}
              record={showModal.data}
            />

        </div>
    )
}

export default UserLists
