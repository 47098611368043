import React , {useState , useEffect} from 'react'
import { Table , Input, Spin} from 'antd';
import './rental.scss';
import {FiSearch} from 'react-icons/fi'
import {RiEye2Line , RiDeleteBin2Fill , RiEdit2Fill} from 'react-icons/ri';
import {MdAddCircle}from 'react-icons/md'
import moment from 'moment';
import CustomDeleteModal from '../custom_components/customDeleteModal';
import { IconButton, PrimaryButton } from '../custom_components/customButton';
import CustomProductView from '../custom_components/customProductView';
import { error, success } from '../contants/snackbars';
import { addRentalProduct, deleteRentalProduct, fetchRentalProducts, fetchUnverfiedRentalProducts } from '../stores/api_calls/rentals';
import { colorScheme } from '../contants/variables';
import { useNavigate , createSearchParams } from 'react-router-dom';
import Kjur from '../stores/utils/jwt';
import RentalPreviewModal from '../custom_components/rentalPreviewModal';

const UnverifiedCarTab = () => {

    const navigate = useNavigate()
    const [tableData,setTableData] = useState([])
    const [isLoading,setIsLoading] = useState(false)
    const [keywordSearch , setKeywordSearch] = useState("")
    const [showModal , setShowModal] = useState({showDelete:false , showPreview:false , data:{}})
    const [tablePagination , setTablePagination] = useState({
        current: 1,
        pageSize: 10,
        total:30,
        defaultPageSize: 10, 
        showSizeChanger: true, 
        pageSizeOptions: ['10', '20', '30']
    })

    const columns = [
        {
            title: 'Bike Name',
            dataIndex: 'bikeName',
            // sorter: true,
            render: (text ,record) => `${record.product_name}`,
            // width: '20%',
        },
        {
            title: 'Brand Model',
            dataIndex: 'brand',
            render: (text ,record) => `${record.product_brand_model.model_name}`,
            // width: '20%',
        },
        {
            title: 'Company Name',
            dataIndex: 'client',
            render: (text ,record) => record.rental_company_details.company_name,
        },
        {
            title: 'Date Created',
            dataIndex: 'dateCreated',
            render: (text ,record) => `${moment(record.date_created).format('LL')}`,
        },
        {
            title: 'Rental Type',
            dataIndex: 'price',
            render: (text ,record) => record.product_rental_type,
        },
        {
            title: 'Vehicle Type',
            dataIndex: 'dateSold',
            render: (text ,record) => record.vehicle_type,
        },
        {
            title:'Actions',
            dataIndex:'actions',
            width:'10%',
            render:(text,record)=>(
                <div className='actions-icons-container'>
                    <IconButton className='icon-button view' tootTipTitle="View Bike" Icon={<RiEye2Line/>} onClick={()=>setShowModal({...showModal , showPreview:true , data:record})}/>
                    <IconButton className='icon-button edit' tootTipTitle="Edit Article" Icon={<RiEdit2Fill/>} onClick={()=>goToForm('edit',record)} />
                    <IconButton className='icon-button delete' tootTipTitle="Delete Bike" Icon={<RiDeleteBin2Fill/>} onClick={()=>onDelete(record)}/>
                </div>
            )
        }
    ];

    useEffect(()=>{
        refetchLists()
    },[])

    useEffect(()=>{
        if(tablePagination.current || tablePagination.pageSize){
            refetchLists()
        }
    },[tablePagination.current , tablePagination.pageSize])
    
    const refetchLists = () => {
        const pagination = {
            page:tablePagination.current,
            limit:tablePagination.pageSize,
            keyword:keywordSearch
        }

        fetchData(pagination)
    }

    const fetchData = (data) => {

        setIsLoading(true)

        const getRentals = fetchUnverfiedRentalProducts(data)

        getRentals.then((res)=>{
            if(res.data.success){
                setTableData(res.data.data)
                setTablePagination({
                    ...tablePagination,
                    total:res.data.meta.pagination.total
                })
                setIsLoading(false)
            }
        }).catch((e)=>{
            error(`Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`)
        })
        
    }

    const onEnter = (e) => {
        if (e.key === "Enter") {
            const pagination = {
                page : 1,
                limit : 10,
                keyword:keywordSearch
            }
            fetchData(pagination)
        }
    }

    const goToForm = (mode , data) => {
        if(mode==='edit'){
            // const token = Kjur.encode(data)
            navigate(
                {
                    pathname:`../rentals/${mode}`,
                    search:`?${createSearchParams({ id: data._id })}`,
                },
                {replace:true}
            )
        }else{
            navigate(`../rentals/${mode}`, { replace: true })
        }
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTablePagination(pagination)
    };

    const onPreviewProduct = (record) => {
        setShowModal({...showModal , showPreview:true , data:record})
    }

    const onDelete = (record) => {
        setShowModal({...showModal , showDelete:true , data:record})
    }

    const onDeleteSubmit = () => {
        const filteredData = tableData.filter(el=>el._id!==showModal.data._id)

        const onDelete = deleteRentalProduct(showModal.data._id)
        onDelete.then((res)=>{
            if(res.data.success){
                success("Bike Rental deleted successfully.")
                setTableData(filteredData)
                setShowModal({...showModal , showDelete:false , data:{}})
            }
        }).catch((e)=>{
            error(`${e.response.data.server_response}`)
        })
    }

    return (
        <div>
            <div
                className='search-container'
            >
                <div className='btn-div'>
                    <PrimaryButton
                        Icon={<MdAddCircle/>}
                        label="Add New Rental"
                        color={colorScheme.primary}
                        onClick={()=>goToForm('create')}
                    />
                </div>

                <Input
                    size='large'
                    style={{width:300 , marginLeft:'20px'}}
                    placeholder='Search Record'
                    prefix={<FiSearch/>}
                    onChange={e=>setKeywordSearch(e.target.value)}
                    onKeyPress={onEnter}
                />
            </div>

            <div className='scrollable-table-container'>
                <Table
                    columns={columns}
                    rowKey={record => record._id}
                    dataSource={tableData}
                    pagination={tablePagination}
                    loading={{indicator:<Spin size='large'/> , spinning:isLoading}}
                    onChange={handleTableChange}
                    bordered
                    sticky
                />
            </div>

            <RentalPreviewModal
                visible={showModal.showPreview}
                data={showModal.data}
                onCancel={()=>setShowModal({...showModal , showPreview:false , data:{}})}
            />
            
            <CustomDeleteModal
                title="Delete Bike Ad"
                visible={showModal.showDelete}
                onSubmit={onDeleteSubmit}
                onCancel={()=>setShowModal({...showModal , showDelete:false , data:{}})}
                data={{itemName:`${showModal.data.product_name}` , type:"car rental"}}
            />
        </div>
    )
}

export default UnverifiedCarTab;