import React, { useState, useEffect } from "react";
import { Input, Spin, Table } from "antd";
import "./carlist.scss";
import { GoVerified } from "react-icons/go";
import { FiSearch } from "react-icons/fi";
import { FaTags } from "react-icons/fa";
import { RiEye2Line, RiDeleteBin2Fill, RiEdit2Fill } from "react-icons/ri";
import {
  deleteCarProduct,
  fetchVerifiedCars,
  markAsSoldCarProduct,
} from "../stores/api_calls/carlists";
import moment from "moment";
import CustomDeleteModal from "../custom_components/customDeleteModal";
import { IconButton } from "../custom_components/customButton";
import { createSearchParams, useNavigate } from "react-router-dom";
import Kjur from "../stores/utils/jwt";
import CustomConfirmModal from "../custom_components/customeConfirmModal";
import CustomProductView from "../custom_components/customProductView";
import { error, success } from "../contants/snackbars";
import { sendSMS } from "../stores/api_calls/upload";

const VerifiedNewCarLists = () => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const [keywordSearch, setKeywordSearch] = useState("");
  const [showModal, setShowModal] = useState({
    showDelete: false,
    showPreview: false,
    showAsSold: false,
    data: {},
  });
  const [tablePagination, setTablePagination] = useState({
    current: 1,
    pageSize: 10,
    total: "",
    defaultPageSize: 10,
    showSizeChanger: true,
    showQuickJumper: true,
    showTotal: (total) => `Total ${total} items`,
    pageSizeOptions: ["10", "20", "30"],
  });
  const columns = [
    {
      title: "Date Created",
      dataIndex: "dateCreated",
      render: (text, record) =>
        moment.utc(record.date_created).local().format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: "Date Updated",
      dataIndex: "dateUpdated",
      render: (text, record) =>
        moment.utc(record.date_updated).local().format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: "Bike Name",
      dataIndex: "bikeName",
      // sorter: true,
      render: (text, record) => `${record.product_name}`,
    },
    {
      title: "Bike Plate Number",
      dataIndex: "bikePlateNumber",
      render: (text, record) => `${record.car_plate_number ?? "Unknown"}`,
    },
    {
      title: "Brand Model",
      dataIndex: "brand",
      render: (text, record) =>
        `${record?.product_brand_model?.model_name ?? "Unknown"}`,
    },
    {
      title: "Direct Seller",
      dataIndex: "directSeller",
      render: (text, record) =>
      `${record.advertisement_contact_details?.direct_seller == true ? record.advertisement_contact_details?.name : ''}`,
    },
    {
      title: "Advertiser / Dealer",
      dataIndex: "advertiser",
      render: (text, record) =>
        // `${record.advertisement_contact_details?.name ?? "TBA"} `,
        `${record.advertisement_contact_details?.direct_seller == false ? record.advertisement_contact_details?.name : ''}`,
    },

    {
      title: "Date Verified",
      dataIndex: "dateVerified",
      render: (text, record) =>
        moment.utc(record.date_verified).local().format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: "Verified by",
      dataIndex: "VerifiedBy",
      render: (text, record) => `${record.verified_by ?? record.created_by}`,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (text, record) => (
        <div className="actions-icons-container">
          <IconButton
            className="icon-button view"
            tootTipTitle="View Bike"
            Icon={<RiEye2Line />}
            onClick={() => onPreviewProduct(record)}
          />
          {/* <IconButton className='icon-button verified' tootTipTitle="Verified" Icon={<GoVerified/>} onClick={()=>null}/> */}
          <IconButton
            className="icon-button sold"
            tootTipTitle="Mark as Sold"
            Icon={<FaTags />}
            onClick={() => onMarkAsSold(record)}
          />
          <IconButton
            className="icon-button edit"
            tootTipTitle="Edit Bike"
            Icon={<RiEdit2Fill />}
            onClick={() => onEdit(record)}
          />
          <IconButton
            className="icon-button delete"
            tootTipTitle="Delete Bike"
            Icon={<RiDeleteBin2Fill />}
            onClick={() => onDelete(record)}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    refetchLists();
  }, []);

  useEffect(() => {
    if (tablePagination.current || tablePagination.pageSize) {
      refetchLists();
    }
  }, [tablePagination.current, tablePagination.pageSize]);

  const refetchLists = () => {
    const pagination = {
      page: tablePagination.current,
      limit: tablePagination.pageSize,
      keyword: keywordSearch,
    };

    fetchData(pagination);
  };

  const fetchData = (data) => {
    setIsLoading(true);

    const verifiedCars = fetchVerifiedCars({
      ...data,
      productCondition: "New",
    });

    verifiedCars
      .then((res) => {
        if (res.data.success) {
          setTableData(res.data.data);
          setTablePagination({
            ...tablePagination,
            total: res.data.meta.pagination.total,
          });
          setIsLoading(false);
        }
      })
      .catch((e) => {
        error(
          `Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`
        );
      });
  };

  const onEnter = (e) => {
    if (e.key === "Enter") {
      const pagination = {
        page: 1,
        limit: 10,
        keyword: keywordSearch,
      };
      fetchData(pagination);
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTablePagination(pagination);
  };

  const onEdit = (record) => {
    const token = Kjur.encode(record);

    navigate(
      {
        pathname: "../bikes/edit-new",
        search: `?${createSearchParams({ id: record._id })}`,
      },
      { replace: true }
    );
  };

  const onDelete = (record) => {
    setShowModal({ ...showModal, showDelete: true, data: record });
  };

  const onDeleteSubmit = () => {
    const filteredData = tableData.filter(
      (el) => el._id !== showModal.data._id
    );

    const onDelete = deleteCarProduct(showModal.data._id);
    onDelete
      .then((res) => {
        if (res.data.success) {
          setTableData(filteredData);
          setShowModal({ ...showModal, showDelete: false, data: {} });
          success("Bike Deleted Successfully");
        }
      })
      .catch((e) => {
        error(e.response.data.server_response);
      });
  };

  const onPreviewProduct = (record) => {
    setShowModal({ ...showModal, showPreview: true, data: record });
  };

  const onMarkAsSold = (record) => {
    setShowModal({ ...showModal, showAsSold: true, data: record });
  };

  const onMarkAsSoldSubmit = () => {
    const form = {
      message: `Your bike ${showModal.data.product_name} - with plate number ${showModal.data.car_plate_number} is already sold`,
      numberArray: [
        showModal.data.advertisement_contact_details?.user_contact_numbers
          .number,
      ],
    };

    const onMarkAsSold = markAsSoldCarProduct(showModal.data._id);
    onMarkAsSold
      .then((res) => {
        if (res.data.success) {
          const filteredData = tableData.filter(
            (el) => el._id !== showModal.data._id
          );
          setTableData(filteredData);
          setShowModal({ ...showModal, showAsSold: false, data: {} });
          success("Bike marked as sold");

          const sendMessage = sendSMS(form);
          sendMessage
            .then((res) => {
              if (res.data.success) {
                success(`Message sent to ${form.numberArray[0]}`);
              }
            })
            .catch((e) => {
              console.log("Send SMS Failed");
            });
        }
      })
      .catch((e) => {
        error(e.response.data.server_response);
      });
  };

  return (
    <div className="table-container">
      <div className="table-title-icon verified">
        <GoVerified />
      </div>
      <p className="table-title-text verified">Verified New Bike Lists</p>

      <div className="search-container">
        <Input
          size="large"
          style={{ width: 300 }}
          placeholder="Search Record"
          prefix={<FiSearch />}
          onChange={(e) => setKeywordSearch(e.target.value)}
          onKeyPress={onEnter}
        />
      </div>

      <div className='scrollable-table-container'>
        <Table
          columns={columns}
          rowKey={(record) => record._id}
          dataSource={tableData}
          pagination={tablePagination}
          loading={{ indicator: <Spin size="large" />, spinning: isLoading }}
          onChange={handleTableChange}
          bordered
          sticky
        />
      </div>

      <CustomProductView
        title="Product View"
        visible={showModal.showPreview}
        data={showModal.data}
        onCancel={() =>
          setShowModal({ ...showModal, showPreview: false, data: {} })
        }
      />

      <CustomConfirmModal
        title="Mark As Sold"
        visible={showModal.showAsSold}
        onSubmit={onMarkAsSoldSubmit}
        onCancel={() =>
          setShowModal({ ...showModal, showAsSold: false, data: {} })
        }
      >
        <p className="modal-phrase">
          Are you sure you want to mark this bike{" "}
          <span className="name">"{showModal.data.product_name}"</span> as sold.
          By clicking <span>"Okay"</span> , this bike will be listed as sold and
          can be seen at sold bike lists page.
        </p>
      </CustomConfirmModal>

      <CustomDeleteModal
        title="Delete Bike Ad"
        visible={showModal.showDelete}
        onSubmit={onDeleteSubmit}
        onCancel={() =>
          setShowModal({ ...showModal, showDelete: false, data: {} })
        }
        data={{ itemName: `${showModal.data.product_name}`, type: "car ad" }}
      />
    </div>
  );
};

export default VerifiedNewCarLists;
