import {
    Input,
    Row,
    Col,
    Divider,
    DatePicker,
    Space,
    Checkbox,
    Select,
    Tooltip,
    Radio, Upload, Button,
} from "antd";
const { TextArea } = Input;
import React, { useState, useEffect, useRef } from "react";
import { FaDownload, FaUpload, FaUserAlt } from "react-icons/fa";
import { IoMdCloseCircle } from 'react-icons/io';
import { IoInformationCircleSharp } from "react-icons/io5";
import { GoDeviceMobile, GoPackage } from "react-icons/go";
import { RiImageAddFill } from "react-icons/ri";
import { MdEmail, MdOutlineFileUpload } from "react-icons/md";
import "../cars/carform.scss";
import moment from "moment";
import {
    colorScheme,
    bicycleBrands,
    bicycleConditions,
    bicycleTypes,
    reservedStatus,
    bicycleFrameSizes,
    bicycleColors
  } from "../../contants/variables";
import {
    LoadingButton,
    PrimaryButton,
    IconButton
} from "../../custom_components/customButton";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertFromHTML, convertToRaw, ContentState, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { uploadImage } from "../../stores/api_calls/upload";
import { getCommaSeparatedTwoDecimalsNumber } from "../../stores/utils/formatNumber";
import { fetchPackages } from "../../stores/api_calls/carlists";
import { postVideo } from "../../stores/api_calls/upload";
import { error, success } from "../../contants/snackbars";

const launchYears = [];
const lastYear = moment().add(1, 'y').format('YYYY');
for (var i = 2015; i <= lastYear; i++) {
    launchYears.push(i.toString());
}

const FieldSuffix = (props) => {
    const { TooltipData } = props;
    return (
        <Tooltip
            title={() => {
                return <TooltipData />;
            }}
            placement="bottomRight"
        >
            <IoInformationCircleSharp
                color={colorScheme.primary}
                style={{ fontSize: "20px" }}
            />
        </Tooltip>
    );
};


const ImageUploadComponent = (props) => {
    const { onChange } = props;
    const fileUploader = useRef(null);

    const uploadImageCallBack = (link) => {
        onChange(link, "auto", "100%", "uploaded image");
    };

    const onSelectFile = (e) => {
        const file = e.target.files[0];
        // const localUrl = URL.createObjectURL(file)

        const upload = uploadImage(file);

        upload
            .then((res) => {
                if (res.data.success) {
                    const url = res.data.data.metadata.image_url;
                    uploadImageCallBack(url);
                }
            })
            .catch((e) => {
                error(
                    `Opps! ${e.response.data.server_response} . Upload Failed. Please try again`
                );
            });
    };

    const handleOpenGallery = (event) => {
        fileUploader.current.click();
    };

    return (
        <div>
            <div className="icon-button-div">
                <IconButton
                    className="icon-button upload"
                    tootTipTitle="Upload Image"
                    Icon={<RiImageAddFill />}
                    onClick={handleOpenGallery}
                />
            </div>

            <input
                ref={fileUploader}
                hidden
                type="file"
                name="image-event"
                id="image-event"
                accept="image/*"
                onChange={onSelectFile}
            />
        </div>
    );
};

const BicycleDetails = (props) => {
    const [videoFile, setVideoFile] = useState(null);
    const [downloading, setDownloading] = useState(false);
    const [packages, setPackages] = useState([]);
    const fileUploaderLong1 = useRef(null);
    const fileUploaderLong2 = useRef(null);
    const { 
        form,
        setForm,
        dealers,
        isEdit,
        selectedFile1,
        setSelectedFile1,
        selectedFile2,
        setSelectedFile2,
    } = props;

    // const [specsEditorState, setSpecsEditorState] = useState(() => EditorState.createEmpty() );
    //  const [featEditorState, setFeatEditorState] = useState(() =>  EditorState.createEmpty());
    // const [descEditorState, setDescEditorState] = useState(() => EditorState.createEmpty());

    // useEffect(() => {
    //     if (form.descFormatted) {
    //         setDescEditorState(getEditorContent(form.descFormatted, 'descFormatted'));
    //     }
    //     if (form.specsFormatted) {
    //         setSpecsEditorState(getEditorContent(form.specsFormatted, 'specsFormatted'));
    //     }
    // }, [form]);

    useEffect(() => {
        getPackages();
    }, []);

    const isValidRawContentState = (rawContentState) => {
        return rawContentState && rawContentState.blocks && rawContentState.entityMap;
    };

    const getPackages = () => {
        const packages = fetchPackages();
        packages
          .then((res) => {
            if (res.data.success) {
              setPackages(res.data.data);
            }
          })
          .catch((e) => console.log(e));
      };

    // const getEditorContent = (editorState, type) => {

    //     if (typeof editorState === 'object') {
    //         if (isValidRawContentState(editorState)) {
    //             const contentState = convertFromRaw(editorState);
    //             setForm((previous) => {
    //                 return {
    //                     ...previous,
    //                     [type]: EditorState.createWithContent(contentState)
    //                 }
    //             });
    //             return EditorState.createWithContent(contentState);
    //         } else {
    //             return editorState;
    //         }
    //     } else if (typeof editorState === 'string') {
    //         const descHTML = '<p>' + editorState + '</p>';
    //         const descBlocksFromHTML = convertFromHTML(descHTML);
    //         const descContentState = ContentState.createFromBlockArray(
    //             descBlocksFromHTML.contentBlocks,
    //             descBlocksFromHTML.entityMap
    //         );
    //         setForm((previous) => {
    //             return {
    //                 ...previous,
    //                 [type]: EditorState.createWithContent(descContentState)
    //             }
    //         });
    //         return EditorState.createWithContent(descContentState);
    //     } else {
    //         return EditorState.createEmpty();
    //     }
    // }

    const handleFieldChange = (name, e) => {
        setForm({
            ...form,
            [name]: e.target.value,
        });
    };

    const handleNumberFieldChange = (name, value, maxLength = 7) => {
        if (value.length <= maxLength || value == 0) {
            setForm({
                ...form,
                [name]: value,
            });
        }
    };

    const handleSelectPackage = (value) => {
        setForm({ ...form, packageId: value });
    };

    const onCheckboxChange = (name, e) => {
        setForm({
            ...form,
            [name]: e.target.checked,
        });
    };

    const handleDropdownChange = (name, value) => {
        setForm({
            ...form,
            [name]: value,
        });
    };

    function downloadFiles() {
        form?.brochures?.forEach((url, index) => {
            const filename = `file${index + 1}.${getFileType(url)}`; // Generate filename for each file (e.g., file1.pdf, file2.pdf, ...)
            downloadSingleFile(url, filename); // Call downloadFile function for each file URL
        });
    }

    function getFileType(text) {
        const parts = text.split(".");
        return parts[parts.length - 1];
    }

    function downloadSingleFile(url, filename) {
        window.open(url, "_blank");
    }

    // const handleSpecsEditorChange = (state) => {
    //     setSpecsEditorState(state);
    //     setForm({
    //         ...form,
    //         ['specsFormatted']: state,
    //     });
    // };

    // const handleFeatEditorChange = (state) => {
    //   setFeatEditorState(state);
    //   setForm({
    //     ...form,
    //     ["featFormatted"]: state,
    //   });
    // };

    // const handleDescEditorChange = (state) => {
    //     setDescEditorState(state);
    //     setForm({
    //         ...form,
    //         ["descFormatted"]: state,
    //     });
    // };

    const handleOpenGalleryLong1 = (event) => {
        fileUploaderLong1.current.click();
    };

    const handleDeleteSelectedFile1 = () => {
        setSelectedFile1({ ...selectedFile1, file: null, url: "" });
        setForm((previous) => {
            return {...previous, longAdImage1: ""}
        })
    }

    const handleImageChange1 = (e) => {
        setSelectedFile1({ ...selectedFile1, file: null, url: "" });

        if (e.target.files) {
            const file = e.target.files[0]
            const localUrl1 = URL.createObjectURL(file)
            setSelectedFile1({ ...selectedFile1, file: file, url: localUrl1 });
        }

    }

    const handleOpenGalleryLong2 = (event) => {
        fileUploaderLong2.current.click();
    };

    const handleDeleteSelectedFile2 = () => {
        setForm({ ...form, selectedLongAdFile2: null, longAdImage2: "" })
    }

    const handleImageChange2 = (e) => {
        setSelectedFile2({ ...selectedFile2, file: null, url: "" });

        if (e.target.files) {
            const file = e.target.files[0]
            const localUrl2 = URL.createObjectURL(file)
            setSelectedFile2({ ...selectedFile2, file: file, url: localUrl2 });
            setForm({ ...form, longAdImage2: localUrl2 })
        }

    }

    const handleRadioChange = (key, e) => {
    setForm((prev) => ({ ...prev, [key]: e.target.value }));
    };

    const handleChangeVideo = ({ videoFile: newVideoList }) => {
    setVideoFile(newVideoList);
    };

    const handleVideoFileUpload = async ({file, onSuccess, onError}) => {
    
    try {
        const res = await postVideo(file);
        
        if (res?.data?.success) {
        onSuccess(null, file);
        setForm({ ...form, local_video_url: res?.data?.data?.metadata?.file_url });
        } else {
        throw new Error('Upload failed');
        }
    } catch (e) {
        onError(e);
        error(
        e.message || 'Upload failed!'
        );
        console.log(e);
    }
    }

    const beforeUploadVideo = async (file) => {
    const isMP4 = file.type === 'video/mp4';

    if (!isMP4) {
        error('Only MP4 files are allowed!');
        setForm({ ...form, local_video_url: null });
        setVideoFile([]);
        return Upload.LIST_IGNORE; // Prevent the file from being added to the list
    }

    const isValid = await validateVideoDuration(file);
    if (!isValid) {
        error('Video duration must not exceed 3 minutes!');
        setForm({ ...form, local_video_url: null });
        setVideoFile([]);
        return false;
    }

    const previewURL = URL.createObjectURL(file);
    setForm({ ...form, local_video_url: previewURL });

    return true; // Allow the upload
    }

    const validateVideoDuration = (file) => {
    return new Promise((resolve) => {
        const video = document.createElement('video');
        video.preload = 'metadata';

        video.onloadedmetadata = () => {
        URL.revokeObjectURL(video.src);
        resolve(video.duration <= 180); // Check if duration is <= 60 seconds
        };

        video.onerror = () => {
        error('Unable to load video metadata.');
        resolve(false);
        };

        video.src = URL.createObjectURL(file);
    });
    };

    return (
        <div>
            <div className="header-container">
                <p className="header">Dealer Personal Details</p>
                <p className="sub-header">
                    Note: (<span className="text-color-danger">*</span>) fields are required to be filled up
                </p>
            </div>

            <Row>
                <Col span={8}>
                    <div className="form-fields">
                        <p className="form-field-label">
                            Name <span>*</span> :{" "}
                        </p>
                        <Select
                            showSearch
                            value={form.fullname || undefined}
                            loading={dealers.loading}
                            onChange={(value) => handleDropdownChange("dealerId", value)}
                            style={{ width: "100%" }}
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {[
                                {
                                    user_first_name: "NOT ON THE LIST DEALER",
                                    user_last_name: "",
                                    _id: "0",
                                },
                                {
                                    user_first_name: "NOT ON THE LIST DIRECT SELLER",
                                    user_last_name: "",
                                    _id: "1",
                                },
                                ...dealers.data,
                            ].map((el, i) => (
                                <Select.Option key={i} value={el._id}>
                                    {`${el.user_first_name} ${el.user_last_name}`}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                </Col>
                <Col span={8}>
                    <div className="form-fields">
                        <p className="form-field-label">
                            Mobile Number <span>*</span> :{" "}
                        </p>
                        <Input
                            value={form.dealerMobileNumber}
                            prefix={<GoDeviceMobile />}
                            onChange={(text) => handleFieldChange("dealerMobileNumber", text)}
                            disabled={
                                form.dealerId === "0" || form.dealerId === "1" ? false : true
                            }
                        />
                    </div>
                </Col>
                <Col span={8}>
                    <div className="form-fields">
                        <p className="form-field-label">Email:</p>
                        <Input
                            value={form.dealerEmail}
                            prefix={<MdEmail />}
                            onChange={(text) => handleFieldChange("dealerEmail", text)}
                            disabled={
                                form.dealerId === "0" || form.dealerId === "1" ? false : true
                            }
                        />
                    </div>
                </Col>
            </Row>

            {(form.dealerId === "0" || form.dealerId === "1") && (
                <Row>
                    <Col span={8}>
                        <div className="form-fields">
                            <p className="form-field-label">
                                First Name <span>*</span> :{" "}
                            </p>
                            <Input
                                value={form.dealerFirstName}
                                onChange={(text) => handleFieldChange("dealerFirstName", text)}
                            />
                        </div>
                    </Col>
                    <Col span={8}>
                        <div className="form-fields">
                            <p className="form-field-label">Last Name: </p>
                            <Input
                                value={form.dealerLastName}
                                onChange={(text) => handleFieldChange("dealerLastName", text)}
                            />
                        </div>
                    </Col>
                </Row>
            )}

            <Divider />

            {form.role === "Advertiser" ? (
                <>
                    <div>
                        <div className="header-container">
                            <p className="header">Package Options</p>
                            <p className="sub-header">
                                Note: (<span className="text-color-danger">*</span>) for advertisers they must select a plan
                            </p>
                        </div>
                        <Row>
                            {packages.map((item, index) => (
                                <Col span={8} key={index}>
                                    <div
                                        className={`package-card-container ${form.packageId === item._id ? "active" : ""
                                            }`}
                                        onClick={() => handleSelectPackage(item._id)}
                                    >
                                        <div className="package-title">
                                            <p>{item.package_name}</p>
                                            <p>S$ {item.package_cost}</p>
                                        </div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </div>
                    <Divider />
                </>
            ) : null}

            <div className="header-container">
                <p className="header">Bicycle Informations</p>
            </div>

            <Row>
                <Col span={8}>
                    <div className="form-fields">
                        <p className="form-field-label">
                            Make & Model <span>*</span> :{" "}
                        </p>
                        <Input
                            value={form.product_name}
                            onChange={(text) => handleFieldChange("product_name", text)}
                            suffix={
                                <FieldSuffix
                                    TooltipData={() => (
                                        <p style={{ fontSize: "9px" }}>
                                            Name of the bicycle to be displayed in website
                                            <br />
                                            eg: For SALE Toyota 40% Discount.
                                        </p>
                                    )}
                                />
                            }
                        />
                    </div>
                </Col>
                <Col span={8}>
                    <div className="form-fields">
                        <p className="form-field-label">
                            Selling Price <span>*</span>:{" "}
                        </p>
                        <Input
                            type="text"
                            prefix={
                                <div className="fieldPrefix">
                                    <p>S$</p>
                                </div>
                            }
                            value={getCommaSeparatedTwoDecimalsNumber(form.product_price)}
                            onChange={(e) => handleNumberFieldChange("product_price", e.target.value == "" ? 0 : e.target.value.split(",").join(""), 7)}
                        />
                    </div>
                </Col>
                <Col span={8}>
                    <div className="form-fields">
                        <p className="form-field-label">Condition <span>*</span> :{" "} </p>
                        <Select
                            showSearch
                            value={form.condition}
                            onChange={(value) => handleDropdownChange("condition", value)}
                            style={{ width: "100%" }}
                            placeholder="- Please select -"
                            options={bicycleConditions}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={8}>
                    <div className="form-fields">
                        <p className="form-field-label">
                            Bike Brand :{" "}
                        </p>
                        <Select
                            showSearch
                            value={form.brand}
                            onChange={(value) => handleDropdownChange("brand", value)}
                            style={{ width: "100%" }}
                            placeholder="- Please Select -"
                            options={bicycleBrands}
                            allowClear
                        />
                    </div>
                </Col>
                <Col span={8}>
                    <div className="form-fields">
                        <p className="form-field-label">Bicycle Types:{" "} </p>
                        <Select
                            showSearch
                            value={form.type}
                            onChange={(value) => handleDropdownChange("type", value)}
                            style={{ width: "100%" }}
                            placeholder="- Please Select -"
                            options={bicycleTypes}
                            allowClear
                        />
                    </div>
                </Col>                
                <Col span={8}>
                    <div className="form-fields">
                        <p className="form-field-label"> Reserved Status : </p>
                        <Select
                            value={form.reserved_status}
                            onChange={(value) => handleDropdownChange("reserved_status", value)}
                            style={{ width: "100%" }}
                            placeholder="- Please Select -"
                            options={reservedStatus}
                        />
                    </div>
                </Col>
                <Col span={8}>
                    <div className="form-fields">
                    <div style={{marginBottom: 10,}}>
                        <Radio.Group 
                        onChange={(e) => handleRadioChange("video_type", e)} 
                        value={form.video_type}
                        >
                        <Radio value="tiktok">Tiktok</Radio>
                        <Radio value="youtube">Youtube</Radio>
                        <Radio value="local">Local Drive</Radio>
                        </Radio.Group>
                    </div>

                    { form?.video_type == "tiktok" ? (
                        <>
                            {/* <p className="form-field-label">Video Link : </p> */}
                            <Input
                                value={form.tiktok_video}
                                onChange={(text) => handleFieldChange("tiktok_video", text)}
                                placeholder="Tiktok Video Link - Optional"
                            />
                            <p className="form-field-label" style={{color:colorScheme.red, fontSize:12, marginTop:3}}>Video duration must not exceed 3 minutes!</p>
                        </>
                    ): form?.video_type == "youtube" ? (
                        <>
                            {/* <p className="form-field-label">Video Link : </p> */}
                            <Input
                                value={form.youtube_video}
                                onChange={(text) => handleFieldChange("youtube_video", text)}
                                placeholder="Youtube Video Link - Optional"
                            />
                            <p className="form-field-label" style={{color:colorScheme.red, fontSize:12, marginTop:3}}>Video duration must not exceed 3 minutes!</p>
                        </>
                    ):(
                        <>
                            <div style={{display:'flex', justifyContent:'space-between'}}>
                                <div>
                                <Upload
                                    accept="video/mp4"
                                    onChange={handleChangeVideo}
                                    customRequest={handleVideoFileUpload}
                                    beforeUpload={beforeUploadVideo}
                                    fileList={videoFile}
                                >
                                    <Button type="primary">
                                    <MdOutlineFileUpload style={{marginRight:10}} /> <span>Upload Video</span>
                                    </Button>
                                </Upload>
                                <p className="form-field-label" style={{color:colorScheme.red, fontSize:12, marginTop:3}}>Video duration must not exceed 3 minutes!</p>
                                </div>
            
                                {form.local_video_url && (
                                    <div style={{ marginLeft: '20px' }}>
                                    <video
                                        src={form.local_video_url}
                                        controls
                                        width="150"
                                        style={{ border: '1px solid #ccc', borderRadius: '4px' }}
                                    />
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={8}>
                    <div className="form-fields">
                        <Checkbox
                            valuePropName="checked"
                            checked={form.isVerified}
                            onChange={(e) => onCheckboxChange("isVerified", e)}
                        >
                            Is Verified
                        </Checkbox>
                    </div>
                </Col>

                <Col span={8}>
                    <div className="form-fields" >
                        <Checkbox
                            valuePropName="checked"
                            checked={form.isSendEmail}
                            onChange={(e) => onCheckboxChange("isSendEmail", e)}
                        >
                            Is Send Email
                        </Checkbox>
                    </div>
                </Col>
            </Row>

            <Divider />
            <div className="header-container">
                <p className="header">Bicycle Specifications</p>
            </div>
            <Row>
                <Col span={8}>
                    <div className="form-fields">
                        <p className="form-field-label"> Frame Size : </p>
                        <Select
                            value={form.frame_size}
                            onChange={(value) => handleDropdownChange("frame_size", value)}
                            style={{ width: "100%" }}
                            placeholder="- Please Select -"
                            options={bicycleFrameSizes}
                            allowClear
                        />
                    </div>
                </Col>
                <Col span={8}>
                    <div className="form-fields">
                        <p className="form-field-label">
                            Wheel Size :{" "}
                        </p>
                        <Input
                            value={getCommaSeparatedTwoDecimalsNumber(form.wheel_size)}
                            onChange={(e) => handleNumberFieldChange("wheel_size", e.target.value == "" ? 0 : e.target.value.split(",").join(""), 3)}
                            suffix={
                                <div className="fieldPrefix">
                                  <p>Inch</p>
                                </div>
                              }
                        />
                    </div>                    
                </Col>
                <Col span={8}>
                    <div className="form-fields">
                        <p className="form-field-label"> Color : </p>
                        <Select
                            value={form.color}
                            onChange={(value) => handleDropdownChange("color", value)}
                            style={{ width: "100%" }}
                            placeholder="- Please Select -"
                            options={bicycleColors}
                            allowClear
                        />
                    </div>
                </Col>
                <Col span={8}>
                    <div className="form-fields">
                        <p className="form-field-label">
                            Frame :{" "}
                        </p>
                        <Input
                            value={form.frame}
                            onChange={(text) => handleFieldChange("frame", text)}
                        />
                    </div>                    
                </Col>
                <Col span={8}>
                    <div className="form-fields">
                        <p className="form-field-label">
                            Wheel Set :{" "}
                        </p>
                        <Input
                            value={form.wheel_set}
                            onChange={(text) => handleFieldChange("wheel_set", text)}
                        />
                    </div>                    
                </Col>
                <Col span={8}>
                    <div className="form-fields">
                        <p className="form-field-label">
                            Technology :{" "}
                        </p>
                        <Input
                            value={form.technology}
                            onChange={(text) => handleFieldChange("technology", text)}
                        />
                    </div>                    
                </Col>
                <Col span={8}>
                    <div className="form-fields">
                        <p className="form-field-label">
                            Fork :{" "}
                        </p>
                        <Input
                            value={form.fork}
                            onChange={(text) => handleFieldChange("fork", text)}
                        />
                    </div>                    
                </Col>
                <Col span={8}>
                    <div className="form-fields">
                        <p className="form-field-label">
                            Head Set :{" "}
                        </p>
                        <Input
                            value={form.head_set}
                            onChange={(text) => handleFieldChange("head_set", text)}
                        />
                    </div>                    
                </Col>
                <Col span={8}>
                    <div className="form-fields">
                        <p className="form-field-label">
                            Handle Stem :{" "}
                        </p>
                        <Input
                            value={form.handle_stem}
                            onChange={(text) => handleFieldChange("handle_stem", text)}
                        />
                    </div>                    
                </Col>
                <Col span={8}>
                    <div className="form-fields">
                        <p className="form-field-label">
                            Handle Bar :{" "}
                        </p>
                        <Input
                            value={form.handlebar}
                            onChange={(text) => handleFieldChange("handlebar", text)}
                        />
                    </div>                    
                </Col>
                <Col span={8}>
                    <div className="form-fields">
                        <p className="form-field-label">
                            Front Deraileur :{" "}
                        </p>
                        <Input
                            value={form.front_derailleur}
                            onChange={(text) => handleFieldChange("front_derailleur", text)}
                        />
                    </div>                    
                </Col>
                <Col span={8}>
                    <div className="form-fields">
                        <p className="form-field-label">
                            Rear Deraileur :{" "}
                        </p>
                        <Input
                            value={form.rear_derailleur}
                            onChange={(text) => handleFieldChange("rear_derailleur", text)}
                        />
                    </div>                    
                </Col>
                <Col span={8}>
                    <div className="form-fields">
                        <p className="form-field-label">
                            Shifter :{" "}
                        </p>
                        <Input
                            value={form.shifter}
                            onChange={(text) => handleFieldChange("shifter", text)}
                        />
                    </div>                    
                </Col>
                <Col span={8}>
                    <div className="form-fields">
                        <p className="form-field-label">
                            Bottom Bracket :{" "}
                        </p>
                        <Input
                            value={form.bottom_bracket}
                            onChange={(text) => handleFieldChange("bottom_bracket", text)}
                        />
                    </div>                    
                </Col>
                <Col span={8}>
                    <div className="form-fields">
                        <p className="form-field-label">
                            Crank Set :{" "}
                        </p>
                        <Input
                            value={form.crank_set}
                            onChange={(text) => handleFieldChange("crank_set", text)}
                        />
                    </div>                    
                </Col>
                <Col span={8}>
                    <div className="form-fields">
                        <p className="form-field-label">
                            Cassette :{" "}
                        </p>
                        <Input
                            value={form.cassette}
                            onChange={(text) => handleFieldChange("cassette", text)}
                        />
                    </div>                    
                </Col>
                <Col span={8}>
                    <div className="form-fields">
                        <p className="form-field-label">
                            Chain :{" "}
                        </p>
                        <Input
                            value={form.chain}
                            onChange={(text) => handleFieldChange("chain", text)}
                        />
                    </div>                    
                </Col>
                <Col span={8}>
                    <div className="form-fields">
                        <p className="form-field-label">
                            Brakes :{" "}
                        </p>
                        <Input
                            value={form.brakes}
                            onChange={(text) => handleFieldChange("brakes", text)}
                        />
                    </div>                    
                </Col>
                <Col span={8}>
                    <div className="form-fields">
                        <p className="form-field-label">
                            Pedal :{" "}
                        </p>
                        <Input
                            value={form.pedal}
                            onChange={(text) => handleFieldChange("pedal", text)}
                        />
                    </div>                    
                </Col>
                <Col span={8}>
                    <div className="form-fields">
                        <p className="form-field-label">
                            Front Hub :{" "}
                        </p>
                        <Input
                            value={form.front_hub}
                            onChange={(text) => handleFieldChange("front_hub", text)}
                        />
                    </div>                    
                </Col>
                <Col span={8}>
                    <div className="form-fields">
                        <p className="form-field-label">
                            Rear Hub :{" "}
                        </p>
                        <Input
                            value={form.rear_hub}
                            onChange={(text) => handleFieldChange("rear_hub", text)}
                        />
                    </div>                    
                </Col>
                <Col span={8}>
                    <div className="form-fields">
                        <p className="form-field-label">
                            Tires :{" "}
                        </p>
                        <Input
                            value={form.tires}
                            onChange={(text) => handleFieldChange("tires", text)}
                        />
                    </div>                    
                </Col>
                <Col span={8}>
                    <div className="form-fields">
                        <p className="form-field-label">
                            Saddle :{" "}
                        </p>
                        <Input
                            value={form.saddle}
                            onChange={(text) => handleFieldChange("saddle", text)}
                        />
                    </div>                    
                </Col>
                <Col span={8}>
                    <div className="form-fields">
                        <p className="form-field-label">
                            Seat Post :{" "}
                        </p>
                        <Input
                            value={form.seat_post}
                            onChange={(text) => handleFieldChange("seat_post", text)}
                        />
                    </div>                    
                </Col>
                <Col span={8}>
                    <div className="form-fields">
                        <p className="form-field-label">
                            Seat Clamp :{" "}
                        </p>
                        <Input
                            value={form.seat_clamp}
                            onChange={(text) => handleFieldChange("seat_clamp", text)}
                        />
                    </div>                    
                </Col>
                <Col span={8}>
                    <div className="form-fields">
                        <p className="form-field-label">
                            Speed :{" "}
                        </p>
                        <Input
                            value={form.speed}
                            onChange={(text) => handleFieldChange("speed", text)}
                        />
                    </div>                    
                </Col>
                <Col span={8}>
                    <div className="form-fields">
                        <p className="form-field-label">
                            Gears :{" "}
                        </p>
                        <Input
                            value={form.gears}
                            onChange={(text) => handleFieldChange("gears", text)}
                        />
                    </div>                    
                </Col>
                <Col span={8}>
                    <div className="form-fields">
                        <p className="form-field-label">
                            Weight :{" "}
                        </p>
                        <Input
                            value={getCommaSeparatedTwoDecimalsNumber(form.weight)}
                            onChange={(e) => handleNumberFieldChange("weight", e.target.value == "" ? 0 : e.target.value.split(",").join(""), 3)}
                            suffix={
                                <div className="fieldPrefix">
                                  <p>Kg</p>
                                </div>
                              }
                        />
                    </div>                    
                </Col>
            </Row>

            <Divider />
            <div className="header-container">
                <p className="header">Additional Information</p>
            </div>
            <Row>
                <Col span={8}>
                    <div className="form-fields">
                        <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                        >
                            <p className="form-field-label">
                                Accessories :{" "}
                            </p>
                            <FieldSuffix
                                TooltipData={() => (
                                <p style={{ fontSize: "9px" }}>
                                    Accessories refers to additional items in your bicycle. eg: Body kit, Leather Seats, Sports Rim...
                                </p>
                                )}
                            />
                        </div>
                        <TextArea
                            value={form.accessories}
                            onChange={(e) => handleFieldChange("accessories", e) }
                            placeholder="Accessories"
                            autoSize={{ minRows: 5, maxRows: 10 }}
                            maxLength={1000}
                            showCount
                        />
                    </div>
                </Col>
                <Col span={8}>
                    <div className="form-fields">
                        <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                        >
                            <p className="form-field-label">
                                Description :{" "}
                            </p>
                            <FieldSuffix
                                TooltipData={() => (
                                <p style={{ fontSize: "9px" }}>
                                    Description will appear in the main page, so it is the most important comment to add. eg: Urgent sales; 1 careful owner
                                </p>
                                )}
                            />
                        </div>
                        <TextArea
                            value={form.description}
                            onChange={(e) =>
                                handleFieldChange("description", e)
                            }
                            placeholder="Description"
                            autoSize={{ minRows: 5, maxRows: 10 }}
                            maxLength={1000}
                            showCount
                        />
                    </div>
                </Col>
                <Col span={8}>
                    <div className="form-fields">
                        <p className="form-field-label">
                            Notes :{" "}
                        </p>
                        <TextArea
                            value={form.notes}
                            onChange={(e) =>
                                handleFieldChange("notes", e)
                            }
                            placeholder="Notes"
                            autoSize={{ minRows: 5, maxRows: 10 }}
                            maxLength={150}
                            showCount
                        />
                    </div>
                </Col>
                {/* <div className="editor-container">
                    <p className="form-field-label">
                        Specs <span></span> :{" "}
                    </p>
                    <Editor
                        editorState={specsEditorState}
                        onEditorStateChange={handleSpecsEditorChange}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                        toolbar={{
                            inline: { inDropdown: false },
                            list: { inDropdown: true },
                            textAlign: { inDropdown: true },
                            link: { inDropdown: true },
                            history: { inDropdown: true },
                            image: {
                                component: ImageUploadComponent,
                                previewImage: true,
                                uploadEnabled: true,
                                alt: { present: true, mandatory: true },
                            },
                        }}
                    />
                </div> */}
                {/* <div className="editor-container">
                    <p className="form-field-label">
                        Description <span></span> :{" "}
                    </p>
                    <Editor
                        editorState={descEditorState}
                        onEditorStateChange={handleDescEditorChange}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                        toolbar={{
                            inline: { inDropdown: false },
                            list: { inDropdown: true },
                            textAlign: { inDropdown: true },
                            link: { inDropdown: true },
                            history: { inDropdown: true },
                            image: {
                                component: ImageUploadComponent,
                                previewImage: true,
                                uploadEnabled: true,
                                alt: { present: true, mandatory: true },
                            },
                        }}
                    />
                </div> */}
            </Row>

            <Divider />
            <div>
                <div
                    style={{ marginBottom: 10 }}
                >
                    <div className='header-div'>
                        <p className='sub-header-text'>The image should not exceed 2MB(megabyte) and has to be (.jpg , .png) with a minimum size and same ratio of : 600 width x 300 height.</p>
                        <span style={{ color: 'red', fontWeight: 'bold' }}>This Ad space is Admin use only.</span>
                    </div>
                </div>
                <Row>
                    <Col span={8}>
                        <div className="form-fields">
                            {!form.longAdImage1 ?
                                <div>                                    
                                    {
                                        selectedFile1.url ?
                                        <div style={{ width: "100%", height: "auto", overflow: 'hidden', position: "relative" }}>
                                            <div
                                                style={{ position: 'absolute', right: 0, top: 0, backgroundColor: "#FFFFFF", height: 40, width: 40, margin: "0 auto" }}
                                                onClick={() => handleDeleteSelectedFile1()}>
                                                <IoMdCloseCircle style={{ width: 30, height: 30, cursor: 'pointer' }} />
                                            </div>
                                            <img src={selectedFile1.url}
                                                style={{ width: "100%" }}
                                            />
                                        </div>
                                        :
                                        <div className='upload-container col-div' onClick={handleOpenGalleryLong1}>
                                            <FaUpload />
                                            <p>UPLOAD LONG PHOTO</p>
                                        </div>
                                    }
                                    <input
                                        ref={fileUploaderLong1}
                                        id="getFileLong1"
                                        type="file"
                                        hidden
                                        onChange={handleImageChange1}
                                    />
                                </div>
                                :
                                <div style={{ width: "100%", height: "auto", overflow: 'hidden', position: "relative" }}>
                                    <div
                                        style={{ position: 'absolute', right: 0, top: 0, backgroundColor: "#FFFFFF", height: 40, width: 40, margin: "0 auto" }}
                                        onClick={() => handleDeleteSelectedFile1()}>
                                        <IoMdCloseCircle style={{ width: 30, height: 30, cursor: 'pointer' }} />
                                    </div>
                                    <img src={form.longAdImage1}
                                        style={{ width: "100%" }}
                                    />
                                </div>
                            }
                        </div>
                    </Col>
                </Row>
            </div>
            <Divider />
        </div>
    );
};

export default BicycleDetails;
