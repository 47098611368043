import { Modal, Button } from 'antd'
import React from 'react'
import './custom.scss'

const ConfirmActivationModal = (props) => {

    const {title , visible , onSubmit , onCancel , data, loading}=props

    return (
        <Modal
            className='deleteModal'
            title={title}
            visible={visible}
            // onOk={onSubmit}
            onCancel={onCancel}
            footer={[
                <Button key="back" onClick={onCancel}>
                  Cancel
                </Button>,
                <Button key="submit" type="primary" loading={loading} onClick={onSubmit}>
                  Submit
                </Button>,
              ]}
        >
            <p
                style={{
                    margin:0
                }}
            >
                Are you sure you want to 
                {
                    data.is_active ?
                     <span style={{fontWeight: 600}}> deactivate </span> : 
                     <span style={{fontWeight: 600}}> activate </span>
                } 
                this  <span className='data-name'>“ {data.user_first_name} {data.user_last_name} ”</span> ?
            </p>
        </Modal>
    )
}

export default ConfirmActivationModal