import "./plateNumbers.scss";
import React, { useState, useEffect} from "react";
import {  Spin, Table } from "antd";
import { IconButton, PrimaryButton } from "../custom_components/customButton";
import { MdAddCircle } from "react-icons/md";
import { FaTags } from "react-icons/fa";
import {  RiDeleteBin2Fill, RiEdit2Fill } from "react-icons/ri";
import moment from "moment";
import { GoUnverified } from "react-icons/go";
import CustomDeleteModal from "../custom_components/customDeleteModal";
import { colorScheme } from "../contants/variables";
import { error, success } from "../contants/snackbars";
import {
  deletePlateNumber,
  fetchPlateNumbers,
  verifyPlateNumber,
} from "../stores/api_calls/plateNumbers";
import PlateNumberForm from "../forms/plate_numbers/plateNumberForm";

const PlateNumberList = () => {
  const [showForm, setShowForm] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState({
    visible: false,
    data: {},
  });

  const columns = [
    {
      title: "Date Created",
      dataIndex: "date_created",
      key: "date_created",
      render: (text, record) => (
        <span>{moment(record.date_created).format("LL")}</span>
      ),
    },
    {
      title: "Plate Number",
      dataIndex: "car_plate_number",
      key: "car_plate_number",
    },
    {
      title: "Owner",
      dataIndex: "car_plate_owner_name",
      key: "car_plate_owner_name",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <span>{record.is_verified ? "Verified" : "Unverified"}</span>
      ),
    },
    {
      title: "Sold ?",
      dataIndex: "is_sold",
      key: "is_sold",
      render: (text, record) => <span>{record.is_sold ? "Yes" : "No"}</span>,
    },

    {
      title: "Action",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => (
        <div className="actions-icons-container">
          <IconButton
            className="icon-button unverified"
            tootTipTitle={record.is_verified ? "Unverify" : "Verify"}
            Icon={
              <GoUnverified color={record.is_verified ? "#E3D337" : "gray"} />
            }
            onClick={() => handleVerifyPlateNumber(record)}
          />
          <IconButton
            className="icon-button edit"
            tootTipTitle="Edit"
            Icon={<RiEdit2Fill />}
            onClick={() =>
              setShowForm({ isEditing: true, visible: true, data: record })
            }
          />
          <IconButton
            className="icon-button delete"
            tootTipTitle="Delete"
            Icon={<RiDeleteBin2Fill />}
            onClick={() => handleDeletePlateNumber(record)}
          />
        </div>
      ),
    },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tablePagination, setTablePagination] = useState({
    current: 1,
    pageSize: 10,
    total: "",
    defaultPageSize: 10,
    showSizeChanger: true,
    showQuickJumper: true,
    showTotal: (total) => `Total ${total} items`,
    pageSizeOptions: ["10", "20", "30"],
  });

  useEffect(() => {
    const pagination = {
      page: 1,
      limit: 10,
    };

    fetchData(pagination);
  }, []);

  const fetchData = (data) => {
    setIsLoading(true);

    const plateNumbers = fetchPlateNumbers(data);
    plateNumbers
      .then((res) => {
        if (res.data.success) {
          console.log(res.data.data);
          setTableData(res.data.data);
          setIsLoading(false);
          setTablePagination({
            ...tablePagination,
            total: res.data.meta.pagination.total,
          });
        }
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    if (tablePagination.current || tablePagination.pageSize) {
      const pagination = {
        page: tablePagination.current,
        limit: tablePagination.pageSize,
      };
  
      fetchData(pagination);
    }
  }, [tablePagination.current, tablePagination.pageSize]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTablePagination(pagination);
  };

  const addPlateNumberSuccess = () => {
    const pagination = {
      page: 1,
      limit: 10,
    };

    fetchData(pagination);
    setShowForm(false);
  };

  const handleDeletePlateNumber = (record) => {
    setShowDeleteModal({ visible: true, data: record });
  };

  const onDeleteSubmit = () => {
    const onDelete = deletePlateNumber(showDeleteModal.data.id);
    onDelete
      .then((res) => {
        if (res.data.success) {
          success("Plate number deleted successfully.");
          const pagination = {
            page: 1,
            limit: 10,
          };

          fetchData(pagination);
          setShowDeleteModal({ visible: false, data: {} });
        }
      })
      .catch((e) => {
        error(`${e.response.data.server_response}`);
      });
  };

  const handleVerifyPlateNumber = (record) => {
    const onVerify= verifyPlateNumber(record.id);
    onVerify
      .then((res) => {
        if (res.data.success) {
          success(record.is_verified ? "Unverified plate number" : "Verified plate number");
          const pagination = {
            page: 1,
            limit: 10,
          };

          fetchData(pagination);
        }
      })
      .catch((e) => {
        error(`${e.response.data.server_response}`);
      });
  }

  return (
    <div className="career-table-container">
      <div className="table-title-icon primary">
        <FaTags />
      </div>
      <p className="table-title-text primary">Plate Numbers</p>
      <div className="search-container">
        <div className="add-button-container">
          <PrimaryButton
            Icon={<MdAddCircle />}
            color={colorScheme.primary}
            label="Add New Plate Number"
            onClick={() =>
              setShowForm({ isEditing: false, visible: true, data: null })
            }
          />
        </div>

        {/* <Input
          style={{ width: 300 }}
          size="large"
          placeholder="Press Enter to Search Record"
          prefix={<FiSearch />}
          value={""}
          onChange={(e) => {}}
          onKeyPress={() => {}}
        /> */}
      </div>

      <div className='scrollable-table-container'>
        <Table
          columns={columns}
          rowKey={(record) => record._id}
          dataSource={tableData}
          loading={{ indicator: <Spin size="large" />, spinning: isLoading }}
          pagination={tablePagination}
          onChange={handleTableChange}
          bordered
          sticky
        />
      </div>

      <PlateNumberForm
        visible={showForm.visible}
        onCancel={() => setShowForm({...showForm, visible: false})}
        addPlateNumberSuccess={addPlateNumberSuccess}
        isEditing={showForm.isEditing}
        data={showForm.data}
      />

      <CustomDeleteModal
        title={`Delete Plate Number`}
        visible={showDeleteModal.visible}
        onSubmit={onDeleteSubmit}
        onCancel={() => {
          setShowDeleteModal({ ...showDeleteModal, visible: false, data: {} });
        }}
        data={{
          itemName: `${showDeleteModal.data.car_plate_number}`,
          type: "Plate Number",
        }}
      />
    </div>
  );
};

export default PlateNumberList;
