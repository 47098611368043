import { Row, Col, Input, Modal, Select } from "antd";
import React, { useState, useEffect, useRef } from "react";
import {
  addAccessory,
  editAccessory,
  fetchBrands,
  fetchCategories,
} from "../../stores/api_calls/car-workshop";
import "../rentals/rentals.scss";
import RentalUploads from "../rentals/RentalUploads";
import { uploadImage } from "../../stores/api_calls/upload";
import { error, success } from "../../contants/snackbars";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { IconButton } from "../../custom_components/customButton";
import { RiImageAddFill } from "react-icons/ri";

const ImageUploadComponent = (props) => {
  const { onChange } = props;
  const fileUploader = useRef(null);

  const uploadImageCallBack = (link) => {
    onChange(link, "auto", "100%", "uploaded image");
  };

  const onSelectFile = (e) => {
    const file = e.target.files[0];
    // const localUrl = URL.createObjectURL(file)

    const upload = uploadImage(file);

    upload
      .then((res) => {
        if (res.data.success) {
          console.log(res.data.data);
          const url = res.data.data.metadata.image_url;
          uploadImageCallBack(url);
        }
      })
      .catch((e) => {
        error(
          `Opps! ${e.response.data.server_response} . Upload Failed. Please try again`
        );
      });
  };

  const handleOpenGallery = (event) => {
    fileUploader.current.click();
  };

  return (
    <div>
      <div className="icon-button-div">
        <IconButton
          className="icon-button upload"
          tootTipTitle="Upload Image"
          Icon={<RiImageAddFill />}
          onClick={handleOpenGallery}
        />
      </div>

      <input
        ref={fileUploader}
        hidden
        type="file"
        name="image-event"
        id="image-event"
        accept="image/*"
        onChange={onSelectFile}
      />
    </div>
  );
};

const AccessoryForm = (props) => {
  const { visible, onCancel, data } = props;

  // console.log(data);
  const [form, setForm] = useState({ car_workshop_id: data.id, imageUrls: [] });
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [requestLoading, setRequestLoading] = useState(false);
  const [convertedContent, setConvertedContent] = useState(null);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const handleFieldChange = (name, e) => {
    setForm({ ...form, [name]: e.target.value });
  };

  const handleChangeCondition = (value) => {
    setForm({ ...form, condition: value });
  };

  const handleChangeCategory = (value) => {
    setForm({ ...form, categories: value });
  };

  const handleChangeBrand = (value) => {
    setForm({ ...form, suitable_for: value });
  };

  useEffect(() => {
    handleFetchCategories();
    handleFetchBrands();
  }, []);
  const handleFetchCategories = () => {
    const filter = {
      keyword: "",
      page: 1,
      limit: 500,
    };
    const getCategories = fetchCategories(filter);

    getCategories
      .then((res) => {
        if (res.data.success) {
          const transformData = res.data.data.map((item) => ({
            ...item,
            label: item.name,
            value: item.name,
          }));
          setCategories(transformData);
        }
      })
      .catch((e) => {
        error(
          `Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`
        );
      });
  };

  const handleFetchBrands = () => {
    const filter = {
      keyword: "",
      page: 1,
      limit: 500,
    };
    const getBrands = fetchBrands(filter);

    getBrands
      .then((res) => {
        if (res.data.success) {
          const transformData = res.data.data.map((item) => ({
            ...item,
            label: item.name,
            value: item.name,
          }));
          setBrands(transformData);
        }
      })
      .catch((e) => {
        error(
          `Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`
        );
      });
  };

  const handleSubmit = () => {
    setRequestLoading(true);
    // const content = convertToRaw(editorState.getCurrentContent());

    const description = convertToRaw(editorState.getCurrentContent());
    const imagesIds = isEdit
      ? form.imageUrls.map((img) => img._id["$oid"])
      : [];

    if (selectedFiles.length) {
      selectedFiles.forEach((file, index) => {
        const upload = uploadImage(file, index + 1);

        upload.then((res) => {
          if (res.data.success) {
            imagesIds.push(res.data.data._id);
          }

          if (
            imagesIds.length - form.imageUrls.length ===
            selectedFiles.length
          ) {
            const createAccessory = isEdit
              ? editAccessory({
                  ...form,
                  imagesIds,
                  description,
                  car_workshop_id: data.id,
                })
              : addAccessory({
                  ...form,
                  imagesIds,
                  description,
                  car_workshop_id: data.id,
                });
            createAccessory
              .then((res) => {
                if (res?.data?.success) {
                  success(
                    `Bike accessory ${isEdit ? "updated" : "added"} successfully`
                  );
                  resetForm();
                  onCancel();

                  setRequestLoading(false);
                }
              })
              .catch((e) => {
                setRequestLoading(false);
                error(e.response.data.message);
              });
          }
        });
      });
    }
  };

  const handleEditorChange = (state) => {
    setEditorState(state);
  };

  const resetForm = () => {
    setForm({ imageUrls: [], car_workshop_id: data.id });
  };

  return (
    <div>
      <Modal
        className="formModal"
        title={`Add accessory`}
        visible={visible}
        onCancel={onCancel}
        width={800}
        onOk={handleSubmit}
        okText="Submit"
        confirmLoading={requestLoading}
      >
        <div>
          <Row gutter={[20, 20]}>
            <Col span={24}>
              <div className="form-container">
                <p className="form-label">
                  Accessory Name <span>*</span> :{" "}
                </p>
                <Input
                  value={form.title}
                  onChange={(text) => handleFieldChange("title", text)}
                  placeholder="Accessory Name"
                />
              </div>
              <div className="form-container">
                <p className="form-label">
                  Price <span>*</span> :{" "}
                </p>
                <Input
                  value={form.price}
                  onChange={(text) => handleFieldChange("price", text)}
                  placeholder="Price"
                />
              </div>
              <div className="form-container">
                <p className="form-label">
                  Condition <span>*</span> :
                </p>
                <Select
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select condition"
                  defaultValue={[]}
                  onChange={handleChangeCondition}
                  optionLabelProp="label"
                  options={[
                    { label: "Brand New", value: "Brand New" },
                    { label: "Used", value: "Used" },
                  ]}
                  optionRender={(option) => <Space>{option.data.label}</Space>}
                />
              </div>

              <div className="form-container">
                <p className="form-label">
                  Categories <span></span> :{" "}
                </p>
                <Select
                  mode="multiple"
                  style={{
                    width: "100%",
                  }}
                  placeholder="select categories"
                  defaultValue={[]}
                  onChange={handleChangeCategory}
                  optionLabelProp="label"
                  options={categories}
                  optionRender={(option) => <Space>{option.data.name}</Space>}
                />
              </div>

              <div className="form-container">
                <p className="form-label">
                  Suitable for <span></span> :{" "}
                </p>
                <Select
                  mode="multiple"
                  style={{
                    width: "100%",
                  }}
                  placeholder="select brands"
                  defaultValue={[]}
                  onChange={handleChangeBrand}
                  optionLabelProp="label"
                  options={brands}
                  optionRender={(option) => <Space>{option.data.name}</Space>}
                />
              </div>

              {/* <div className="form-fields">
                <p className="form-field-label">
                  Description <span></span> :{" "}
                </p>
                <Input.TextArea
                  rows={8}
                  value={form.description}
                  onChange={(text) => handleFieldChange("description", text)}
                />
              </div> */}

              <div className="editor-container">
                <Editor
                  editorState={editorState}
                  onEditorStateChange={handleEditorChange}
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class"
                  toolbar={{
                    inline: { inDropdown: false },
                    list: { inDropdown: true },
                    textAlign: { inDropdown: true },
                    link: { inDropdown: true },
                    history: { inDropdown: true },
                    image: {
                      component: ImageUploadComponent,
                      previewImage: true,
                      uploadEnabled: true,
                      alt: { present: true, mandatory: true },
                    },
                  }}
                />
              </div>
            </Col>
          </Row>

          <div className="rental-form-container">
            <RentalUploads
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              form={form}
              setForm={setForm}
              isEdit={isEdit}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AccessoryForm;
