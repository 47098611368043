import axios from 'axios'
import Kjur from '../utils/jwt';
import moment from 'moment';

const bearerToken = Kjur.encode(JSON.parse(localStorage.getItem('userDetails')))

let axiosConfig = {
    headers: {
        "Content-Type": 'application/json; charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
        "Strict-Transport-Security": 'max-age=90',
        "Authorization":`Bearer ${bearerToken}`
    }
};

export const fetchClients = async (data) => {

    const obj = {
        page:data.page,
        limit:data.limit,
        keyword:data.keyword,
        is_bidding_client: data.is_bidding_client ?? false,
    }

    const encodedData = Kjur.encode(obj)

    return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin/clients?token=${encodedData}`,
        axiosConfig
    )
}

export const addClient = async (client, isBiddingClient) => {

    const data = {
        name: client.clientName,
        slug: client.slug,
        is_bidding_client: isBiddingClient ?? false,
    }

    const encodedData = Kjur.encode(data)

    return await axios.post(`${process.env.REACT_APP_API_BASE_URL}/admin/clients`, 
        {token:encodedData},
        axiosConfig 
    )
}

export const editClient = async (client) => {

    const data = {
        id:client.clientId,
        name: client.clientName,
        slug: client.slug,
        is_bidding_client: client.isBiddingClient ?? false,
    }

    const encodedData = Kjur.encode(data)

    return await axios.put(`${process.env.REACT_APP_API_BASE_URL}/admin/clients/update`, 
        {token:encodedData},
        axiosConfig 
    )
}

export const deleteClient = async (clientId) => {
    const data = {
        id: clientId
    }

    const encodedData = Kjur.encode(data)

    // delete method doesnt do request boyd, it should be params like get
    return await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/admin/clients/delete?token=${encodedData}`, 
        // {token:encodedData},
        axiosConfig 
    )
}