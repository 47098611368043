import React , {useState , useEffect} from 'react'
import { Table , Input, Spin} from 'antd';
import './comment.scss'
import {FiSearch} from 'react-icons/fi'
import {RiEye2Line , RiDeleteBin2Fill , RiEdit2Fill} from 'react-icons/ri'
import moment from 'moment'
import {FaBuysellads} from 'react-icons/fa'
import CustomDeleteModal from '../custom_components/customDeleteModal';
import { IconButton, PrimaryButton } from '../custom_components/customButton';
import {MdAddCircle} from 'react-icons/md';
import BidCommentForm from '../forms/bid-comments/BidCommentForm';
import { colorScheme } from '../contants/variables';
import { deleteComment, fetchComments } from '../stores/api_calls/bid-comments';
import { error, success } from '../contants/snackbars';


const CommentLists = () => {

    const [tableData,setTableData] = useState([])
    const [isLoading,setIsLoading] = useState(false)
    const [isEdit , setIsEdit] = useState(false)
    const [keywordSearch , setKeywordSearch] = useState("")
    const [showModal , setShowModal] = useState({showDelete:false , showForm:false , showPreview:false ,  data:{}})
    const [tablePagination , setTablePagination] = useState({
        current: 1,
        pageSize: 10,
        total:10,
        defaultPageSize: 10, 
        showSizeChanger: true, 
        pageSizeOptions: ['10', '20', '30']
    })

    const columns = [
        {
            title: 'Product Name',
            dataIndex: 'productName',
            // sorter: true,
            render: (text ,record) => `${record.product_details.product_name}`,
        },
        {
            title: 'Comment User',
            dataIndex: 'commentUser',
            render: (text ,record) => `${record.created_by}`,
        },
        {
            title: 'Comment Text',
            dataIndex: 'commentText',
            render: (text ,record) => `${record.comment_text}`,
        },
        {
            title: 'Comment Type',
            dataIndex: 'commentType',
            render: (text ,record) => `${record.comment_type}`,
        },
        {
            title: 'Verified By',
            dataIndex: 'verifiedBy',
            render: (text ,record) => `${record.verified_by}`,
        },
        {
            title: 'Verified Date',
            dataIndex: 'verifiedDate',
            render: (text ,record) => `${moment(record.date_verified).format('LL')}`,
        },
        {
            title: 'Created Date',
            dataIndex: 'createdDate',
            render: (text ,record) => `${moment(record.date_created).format('LL')}`,
        },
        
        {
            title:'Actions',
            dataIndex:'actions',
            render:(text,record)=>(
                <div className='actions-icons-container'>
                    <IconButton className='icon-button edit' tootTipTitle="Edit" Icon={<RiEdit2Fill/>} onClick={()=>onOpenForm('edit' , record)}/>
                    <IconButton className='icon-button delete' tootTipTitle="Delete" Icon={<RiDeleteBin2Fill/>} onClick={()=>onDelete(record)}/>
                </div>
            )
        }
    ];

    useEffect(()=>{
        refetchLists()
    },[])

    useEffect(()=>{
        if(tablePagination.current || tablePagination.pageSize){
            refetchLists()
        }
    },[tablePagination.current , tablePagination.pageSize])

    const refetchLists = () => {
        const pagination = {
            page:tablePagination.current,
            limit:tablePagination.pageSize,
            keyword:keywordSearch,
            is_verified: true,
            comment_type: 'comment',
        }

        fetchData(pagination)
    }

    const fetchData = (data) => {

        setIsLoading(true)

        const bidComments = fetchComments(data)
        
        bidComments.then((res)=>{
            if(res.data.success){
                // console.log(res.data.data);
                setTableData(res.data.data)
                setTablePagination({
                    ...tablePagination,
                    total:res.data.meta.pagination.total
                })
                setIsLoading(false)
            }
        }).catch((e)=>{
            error(`Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`)
        })
    }

    const onEnter = (e) => {
        if (e.key === "Enter") {
            const pagination = {
                page : 1,
                limit : 10,
                keyword:keywordSearch,
                is_verified: true,
                comment_type: 'comment',
            }
            fetchData(pagination)
        }
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTablePagination(pagination)
    };

    const onDelete = (record) => {
        setShowModal({...showModal , showDelete:true , data:record})
    }

    const onDeleteSubmit = () => {
        const removeBanner = deleteComment(showModal.data._id)
        removeBanner.then((res)=>{
            if(res.data.success){
                success("Comment Deleted Successfully")
                setShowModal({...showModal , showDelete:false , data:{}})
                refetchLists()
            }
        }).catch((e)=>{
            error(`Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`)
        })
    }

    const onOpenForm = (action , record) => {
        if(action==="add"){
            setIsEdit(false)
            setShowModal({...showModal , showForm:true})
        }else{
            setIsEdit(true)
            setShowModal({...showModal , showForm:true , data:record})
        }
    }

    const onSubmitForm = () => {
        setShowModal({...showModal , showForm:false , data:{}})
    }

    return (
        <div className='banner-table-container'>
            <div className='table-title-icon primary'>
                <FaBuysellads/>
            </div>
            <p className='table-title-text primary'>Bidding Comment Lists</p>

            <div
                className='search-container'
            >
                {/* <div className='add-button-container'>
                    <PrimaryButton Icon={<MdAddCircle/>} color={colorScheme.primary} label="Create Ad Banner" onClick={()=>onOpenForm('add')}/>
                </div> */}

                <Input
                    style={{width:300}}
                    size='large'
                    placeholder='Search Record'
                    prefix={<FiSearch/>}
                    onChange={e=>setKeywordSearch(e.target.value)}
                    onKeyPress={onEnter}
                />
            </div>
            
            <div className='scrollable-table-container'>
                <Table
                    columns={columns}
                    rowKey={record => record._id}
                    dataSource={tableData}
                    pagination={tablePagination}
                    loading={{indicator:<Spin size='large'/> , spinning:isLoading}}
                    onChange={handleTableChange}
                    bordered
                    sticky
                />
            </div>

            <BidCommentForm
                isEdit={isEdit}
                visible={showModal.showForm}
                onSubmit={onSubmitForm}
                onCancel={()=>{
                    setShowModal({...showModal , showForm:false , data:{}})
                    setIsEdit(false)
                }}
                data={showModal.data}
                fetchData={fetchData}
                tablePagination={tablePagination}
                verifyStatus={true}
            />
            
            <CustomDeleteModal
                title="Delete Comment"
                visible={showModal.showDelete}
                onSubmit={onDeleteSubmit}
                onCancel={()=>setShowModal({...showModal , showDelete:false , data:{}})}
                data={{itemName:`${showModal.data.comment_text}` , type:"bidding comment"}}
            />

        </div>
    )
}

export default CommentLists;
