import React from 'react'
import '../cars/carform.scss'
import {FaStar} from 'react-icons/fa'
import { Col, Divider, Input, Row, Tabs } from 'antd';
import {MdOutlineArrowBackIos , MdOutlineArrowForwardIos} from 'react-icons/md';
import {FaCalendarDay , FaTachometerAlt , FaCarCrash ,FaCar, FaUsers} from 'react-icons/fa';
import {GiGearStickPattern , GiGears} from 'react-icons/gi';
import {IoMdPin} from'react-icons/io';

export const WebView = (props) => {

    const {form , localImageUrls} = props

    return (
        <div className='web-view-container'>

            <Row>
                <Col span={16}>
                    <div className='product-container'>
                        <div className='image-container'>
                            <img src={localImageUrls[0]}/>
                        </div>

                        <div className='header-name-container'>
                            <div>
                                <p className='type'>{form.type}</p>
                                <p className='name'>{form.product_name}</p>
                            </div>
                            <div>
                                <div className='slider-icon-container'>
                                    <div className='slider-icon'>
                                        <MdOutlineArrowBackIos/>
                                    </div>
                                    <div className='slider-icon'>
                                        <MdOutlineArrowForwardIos/>
                                    </div>
                                </div>

                                <p className='price'>S$ {form.product_price?.toLocaleString()}</p>
                            </div>
                        </div>

                        <div className='overview'>
                            <p className='header-section'>OVERVIEW</p>
                            <p>{form.description}</p>
                        </div>

                        <Divider/>

                        <div className='overview'>
                            <p className='header-section'>TECHNICAL SPECIFICATION</p>
                            <Row gutter={[10,10]} type="flex">
                                <Col span={8}>
                                    <div className='tech-specs-container'>
                                        <FaCalendarDay/>
                                        <div>
                                            <p className='tech-spec-label'>FRAME SIZE</p>
                                            <p className='tech-spec-value'>{form.frame_size}</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div className='tech-specs-container'>
                                        <GiGears/>
                                        <div>
                                            <p className='tech-spec-label'>WHEEL SIZE</p>
                                            <p className='tech-spec-value'>{form.wheel_size}</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div className='tech-specs-container'>
                                        <FaCar/>
                                        <div>
                                            <p className='tech-spec-label'>BICYCLE TYPE</p>
                                            <p className='tech-spec-value'>{form.type}</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div className='tech-specs-container'>
                                        <FaCarCrash/>
                                        <div>
                                            <p className='tech-spec-label'>CONDITION</p>
                                            <p className='tech-spec-value'>{form.condition}</p>
                                        </div>
                                    </div>
                                </Col>
                                
                            </Row>
                        </div>

                        <Divider/>

                        <div className='overview'>
                            <p className='header-section'>ACCESSORIES</p>
                            <p>{form.accessories}</p>
                        </div>

                        <Divider/>
                        <div className='overview'>
                            <p className='header-section'>NOTES</p>
                            <p>{form.notes}</p>
                        </div>

                        <Divider/>

                        <div className='review-container'>
                            <p className='header-section'>SUBMIT YOUR REVIEWS</p>

                            <Input placeholder='Add your review to this car'/>

                            <p className='comments'>0 comments</p>
                        </div>

                    </div>
                </Col>
                <Col span={8}>
                    <div className='sides-container'>
                        <div className='side-div'>
                            <p>DEALER INFO</p>
                        </div>
                        <div className='side-div'>
                            <p>DEALER DIRECT CONTACT</p>
                        </div>
                        <div className='side-div'>
                            <p>LOAN CALCULATOR</p>
                        </div>
                        <div className='side-div'>
                            <p>ADS</p>
                        </div>
                    </div>
                </Col>
            </Row>
            
        </div>
    )
}

export const MobileView = (props) => {

    const {form , localImageUrls} = props
    // console.log(data)
    return (
        <div className='mobile-view-container'>
            <div className='image-container'>
                <img src={localImageUrls[0]}/>
            </div>
            <div className='header-name-container'>
                <div>
                    <p className='name'>{form.product_name}</p>
                    <p className='price'>S$ {form.product_price}</p>
                </div>
                <FaStar/>
            </div>

            <div className='technical-specs-container'>
                <Row>
                    <Col span={12}>
                        <div className='spec-container'>
                            <GiGearStickPattern/>
                            <p>Brand : {form.brand}</p>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className='spec-container'>
                            <GiGears/>
                            <p>Color : {form.color}</p>
                        </div>
                    </Col>
                </Row>
                <Divider className='divider'/>
                <Row>
                    <Col span={12}>
                        <div className='spec-container'>
                            <FaCalendarDay/>
                            <p>Frame Size : {form.frame_size}</p>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className='spec-container'>
                            <FaTachometerAlt/>
                            <p>Wheel Size : {form.wheel_size}</p>
                        </div>
                    </Col>
                </Row>
                <Divider className='divider'/>
                <Row>
                    <Col span={12}>
                        <div className='spec-container'>
                            <FaCar/>
                            <p>Type : {form.type}</p>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className='spec-container'>
                            <FaCarCrash/>
                            <p>Condition : {form.condition}</p>
                        </div>
                    </Col>
                </Row>                
            </div>

            <div className='details-container'>
                <Tabs centered>
                    <Tabs.TabPane
                        tab="Description" key="1"
                    >
                        <p>{form.description}</p>
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        tab="Accessories" key="2"
                    >
                        <p>{form.accessories}</p>
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        tab="Notes" key="3"
                    >
                        <p>{form.notes}</p>
                    </Tabs.TabPane>
                </Tabs>
            </div>

            <div className='review-container'>
                <p className='review-title'>Reviews</p>

                <Input placeholder='Add your review to this car'/>

                <p className='comments'>0 comments</p>
            </div>

        </div>
    )
}
